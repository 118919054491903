

export const aboutUs = {
    about_us: {
        title: {
            es: "Quienes Somos",
            en: "About Us",
            fr: "Qui sommes-nous"
        },
        content:{
            es: "Somos una agencia de Tour Operador, debidamente acreditada por la Autoridad de Turismo de Panamá, compuesta por un equipo joven y dinámico de panameños canadienses, con amplia experiencia en organizar paquetes vacacionales que comprende: transportes, hospedajes, guías certificados, tours, y mucho más.  Déjenos conocer cuáles son sus expectativas en su próxima visita, y nos dará placer asesorarlo y realizar la logística completa durante su estadía.  Dedíquese a explorar y disfrutar, simplemente Viva la Experiencia!",
            en: "We are a Tour Operator agency, duly accredited by the AboutPanama Tourism Authority, made up of a young and dynamic team of Panamanians Canadians, with extensive experience in organizing vacation packages that include: transportation, lodging, certified guides, tours, and much more . Let us know what your expectations are on your next visit, and we will be happy to advise you and carry out the complete logistics during your stay. Dedicate yourself to explore and enjoy, just Live the Experience!",
            fr: "Nous sommes une agence de voyagistes, dûment accréditée par l'Autorité du tourisme du AboutPanama, composée d'une équipe jeune et dynamique de Canadiens panaméens, possédant une vaste expérience dans l'organisation de forfaits vacances comprenant : le transport, l'hébergement, des guides certifiés, des visites et bien plus encore. Faites-nous part de vos attentes lors de votre prochaine visite, nous nous ferons un plaisir de vous conseiller et de prendre en charge toute la logistique pendant votre séjour. Consacrez-vous à explorer et à apprécier, vivez simplement l'expérience!"
        }
    },
    mission:{
        title: {
            es: "Misión",
            en: "Mission",
            fr: "Mission"
        },
        description:{
            es: "Ofrecer un servicio Logístico en Turismo de Calidad y con mucho profesionalismo a todos los visitantes y personas que lo soliciten.",
            en: "Offer a Logistics service in Quality Tourism and with a lot of professionalism to all visitors and people who request it.",
            fr: "Offrir un service Logistique en Tourisme de Qualité et avec beaucoup de professionnalisme à tous les visiteurs et personnes qui en font la demande."
        }
    },
    vision:{
        title: {
            es: "Visión",
            en: "Vision",
            fr: "Vision"
        },
        description: {
            es: "Posicionarnos como la agencia más consultada y referida en la ciudad de Panamá, por la calidad de sus servicios.",
            en: "Position ourselves as the most consulted and referred agency in AboutPanama City, due to the quality of its services.",
            fr: "Nous positionner comme l'agence la plus consultée et référée à AboutPanama City, en raison de la qualité de ses services."
        }
    }
}