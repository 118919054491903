import {CHANGE_LANGUAGE, SET_LANGUAGE} from "../actions/languageAction";

const initialState = {
    selected_language: "es"
};

const languageReducer = (state = initialState, action) => {
    const {type, language} = action;
    switch (type) {
        case CHANGE_LANGUAGE:
        case SET_LANGUAGE:
            return {
                selected_language: language
            }
        default:
            return state;

    }
};

export default languageReducer;