import content from "./content";
import {useSelector} from "react-redux";
import {Card, CardGroup, Container, Row, CardImg, CardText, Button, CardTitle} from "reactstrap";
import SectionTitle from "../../../components/common/section-title";
import BlogBox from "../../../components/Blog/blog-box";
import React from "react";
import {useNavigate} from "react-router-dom";

export const YourNextTrip = () => {

    const ln = useSelector((state) => state.language.selected_language);
    const topTrips = content.yourNextTrip;
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <section className="section bg-light" id="blog">
                <Container>
                    {/* Render section title */}

                    <SectionTitle
                        title={<h5 className={"amatic-font-stlye"}>{topTrips.title[ln]}</h5>}
                        description={<p></p>}
                    />



                    <CardGroup>

                        {topTrips.trips.map((trip, key) => (
                            <Card body className="text-center" style={{backgroundColor: "#ffffff00", border: "0px"}}>
                                <CardTitle>{trip.title[ln]}</CardTitle>
                                <CardImg height="100%" width="100%" src={trip.images[0]} alt="Card image cap" />
                                <CardText></CardText>
                                <Button onClick={() => navigate(`${trip.path}`)} style={{backgroundColor:"#155472"}}>Ver mas</Button>
                            </Card>
                        ))}
                    </CardGroup>


                </Container>
            </section>
        </React.Fragment>
    )

}