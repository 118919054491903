import React from "react";
import {Col, Row, Button} from "reactstrap";
// import {WhatsAppOutlined} from "@ant-design/icons";
import {FaFacebookF, FaInstagram} from "react-icons/fa"
import {BsWhatsapp} from "react-icons/bs"
import {footerContent} from "../../../content/footer";
import {useSelector} from "react-redux";
import SendMailForm from "../../../utils/SendMail/components/SendMailForm";




const Content = () => {

    const language = useSelector((state) => state.language.selected_language);

    return (
        <Row style={{paddingTop: "20px"}} id={"contact"}>
            <Col xs={12} lg={4}>
                <p style={{textAlign: 'center'}}>
                    <div className={"custom-title-2"}>
                        <h4 style={{color: 'white'}}>{footerContent.gamaToursSection.title}</h4>
                    </div>

                    <p>
                        <a href={footerContent.gamaToursSection.items.home.link}
                           style={{fontSize: '20px', color: '#EEBC69'}}>{footerContent.gamaToursSection.items.home[language]}</a>
                    </p>

                    <p>
                        <a href={footerContent.gamaToursSection.items.panama.link}
                           style={{fontSize: '20px', color: '#EEBC69'}}>{footerContent.gamaToursSection.items.panama[language]}</a>
                    </p>

                    <p>
                        <a href={footerContent.gamaToursSection.items.tours.link}
                           style={{fontSize: '20px', color: '#EEBC69'}}>{footerContent.gamaToursSection.items.tours[language]}</a>
                    </p>

                    <p>
                        <a href={footerContent.gamaToursSection.items.todo.link}
                           style={{fontSize: '20px', color: '#EEBC69'}}>{footerContent.gamaToursSection.items.todo[language]}</a>
                    </p>

                </p>

            </Col>
            <Col xs={12} lg={4}>
                <p style={{textAlign: 'center'}}>
                    <div className={"custom-title-2"}>
                        <h4 style={{color: 'white'}}>{footerContent.contactUsSection.title[language]}</h4>
                    </div>
                    <Row>

                        <Col sm={12} style={{margin: '50px 0px 10px 0px'}}>
                            <h4 style={{color: '#EEBC69'}}>
                                panama@gamatourspty.com
                            </h4>
                        </Col>

                        <Col sm={12} style={{margin: '10px 0px 10px 0px'}}>
                            <h4 style={{color: 'white'}}>
                                (+507) 6581-3537
                            </h4>
                        </Col>

                        <Col sm={12} style={{margin: '10px 0px 10px 0px'}}>
                            <Row>
                                <Col>
                                    <p style={{textAlign: 'center'}}>
                                        <FaFacebookF className={"social-media-icons"} size={30} onClick={() => window.open("https://www.facebook.com/gamatourspty")}/>
                                    </p>
                                </Col>
                                <Col>
                                    <p style={{textAlign: 'center'}}>
                                       <FaInstagram className={"social-media-icons"}  size={30} onClick={() => window.open("https://www.instagram.com/gamatourspty/?hl=en")}/>
                                    </p>
                                </Col>
                                <Col>
                                    <p style={{textAlign: 'center'}}>
                                        <BsWhatsapp className={"social-media-icons"} size={30} onClick={() => window.open("https://api.whatsapp.com/send/?phone=50765813537&text&app_absent=0&lang=es")}/>
                                        {/*<Button type="primary"*/}
                                        {/*        onClick={() => window.open("https://api.whatsapp.com/send/?phone=50765813537&text&app_absent=0&lang=es")}*/}
                                        {/*        size={'large'} style={{*/}
                                        {/*    backgroundColor: 'rgba(255,255,255,0)',*/}
                                        {/*    borderColor: 'white',*/}
                                        {/*    borderWidth: '2px'*/}
                                        {/*}} shape="circle"*/}
                                        {/*        icon={*/}
                                        {/*    <BsWhatsapp/>*/}
                                        {/*        }/>*/}
                                    </p>
                                </Col>
                            </Row>
                        </Col>


                    </Row>

                </p>
            </Col>
            <Col xs={12} lg={4}>
                <p style={{textAlign: 'center'}}>
                    <div className={"custom-title-2"}>
                        <h4 style={{color: 'white'}}>{footerContent.messageForm.title[language]}</h4>
                    </div>

                    <SendMailForm footer={true}/>

                </p>
            </Col>
        </Row>
    )

}

export default Content;
