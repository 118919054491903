import React, {Component, useEffect, useState} from "react";
import {
    Navbar,
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Container,
    Collapse,
    Button,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";

//Import Stickey Header
import StickyHeader from "react-sticky-header";
import "../../../node_modules/react-sticky-header/styles.css";
import {useSelector} from "react-redux";
import {navbarcontent} from "../../content/navbarcontent";
import Flags from 'country-flag-icons/react/3x2'
import store from "../../redux/store";
import {changeLanguage as changeLanguageState} from "../../redux/actions/languageAction";
import {useNavigate} from "react-router-dom";
// class Navbar_Page extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       navItems: [
//         { id: 1, idnm: "home", navheading: "Home" },
//         { id: 2, idnm: "features", navheading: "Features" },
//         { id: 3, idnm: "services", navheading: "Services" },
//         { id: 3, idnm: "about", navheading: "About" },
//         { id: 4, idnm: "pricing", navheading: "Pricing" },
//         { id: 5, idnm: "blog", navheading: "Blog" },
//         { id: 6, idnm: "contact", navheading: "Contact" },
//       ],
//       isOpenMenu: false,
//     };
//   }
//
//   toggle = () => {
//     this.setState({ isOpenMenu: !this.state.isOpenMenu });
//   };
//
//   render() {
//     //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
//     let TargetId = this.state.navItems.map((item) => {
//       return item.idnm;
//     });
//
//
//   }
// }
//
// export default Navbar_Page;


export const NavigationBar = (props) => {

    const ln = useSelector((state) => state.language.selected_language);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [navItems] = useState(navbarcontent);
    const navigate = useNavigate();

    let TargetId = [];
    const languages = [
        {id: 0, language: "es", flag: <Flags.ES style={{width:"20px"}} title="Spanish"/>},
        {id: 1, language: "en", flag: <Flags.US style={{width:"20px"}} title="English"/>},
        {id: 2, language: "fr", flag: <Flags.FR style={{width:"20px"}} title="French"/>}
    ]


    const toggle = () => {
        setIsOpenMenu(!isOpenMenu);
    }

    const changeLanguage = () =>{
        const item = languages.find(item => item.language === ln);
        const next = (item.id + 1) < languages.length ? (item.id + 1) : 0;
        store.dispatch(
            changeLanguageState(languages[next].language)
        )
    }

    const renderNavBarItems = () => {
        return navItems[ln].map((item, idx) => {
            if (item.id !== 1) {

                switch (item.name){
                    case "bio_protocol":
                        return (
                            <NavItem style={{cursor:"pointer"}} key={item.id}>
                                <NavLink onClick={() => window.open(item.path)}>
                                    {" "}
                                    {item.label}
                                </NavLink>
                            </NavItem>
                        )
                    case "contact":
                        return (
                            <NavItem style={{cursor:"pointer"}} key={item.id}>
                                <NavLink onClick={() => window.location.href=item.path}>
                                    {" "}
                                    {item.label}
                                </NavLink>
                            </NavItem>
                        )
                    default:
                        return (
                            <NavItem style={{cursor:"pointer"}} key={item.id}>
                                <NavLink onClick={() => navigate(`/${item.path}`)}>
                                    {" "}
                                    {item.label}
                                </NavLink>
                            </NavItem>
                        )
                }
            }
        })
    }

    // useEffect(() => {
    //     // setNavItems(navbarcontent[ln]);
    //     // TargetId = navbarcontent[language].map((item, idx) => {
    //     //     return item.name
    //     // })
    // }, [renderNavBarItems()]);

    return (
        <React.Fragment>
            <StickyHeader
                header={
                    <Navbar
                        expand="lg"
                        fixed="top"
                        className={props.navClass + " navbar-custom sticky sticky-dark"}
                    >
                        <Container>
                            <NavbarBrand className="logo" href="/">
                                <div id="brand-logo"></div>
                            </NavbarBrand>

                            <NavbarToggler className="p-0" onClick={toggle}>
                                <i className="mdi mdi-menu"></i>
                            </NavbarToggler>

                            <Collapse
                                id="navbarCollapse"
                                isOpen={isOpenMenu}
                                navbar
                                style={{
                                    textAlign:"center"
                                }}
                            >
                                <ScrollspyNav
                                    scrollTargetIds={TargetId}
                                    activeNavClass="active"
                                    scrollDuration="800"
                                    headerBackground="true"
                                >
                                    <Nav className="navbar-nav navbar-center" id="mySidenav">
                                        {renderNavBarItems()}

                                        <NavItem key={"language"} style={{float: "right"}}>
                                                <Button className={"btn-language"} onClick={changeLanguage}>
                                                    {languages.find(item => item.language === ln).flag}
                                                </Button>
                                        </NavItem>

                                    </Nav>
                                </ScrollspyNav>
                                {/*<div className="nav-button ms-auto">*/}
                                {/*    <Nav className="navbar-right" navbar>*/}
                                {/*        <NavItem>*/}
                                {/*            <Button*/}
                                {/*                type="button"*/}
                                {/*                className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"*/}
                                {/*            >*/}
                                {/*                Try it Free*/}
                                {/*            </Button>*/}
                                {/*        </NavItem>*/}
                                {/*    </Nav>*/}
                                {/*</div>*/}
                            </Collapse>
                        </Container>
                    </Navbar>
                }
                stickyOffset={-100}
            />
            <div style={{marginTop:"130px"}}></div>
        </React.Fragment>
    );

}
