import React, { Component } from 'react';
import {AboutPanama} from "./components/AboutPanama";
import {PanamaInfo} from "./components/PanamaInfo";


export const Panama = () => {

    return (
        <React.Fragment>
            <AboutPanama/>
            <PanamaInfo/>
        </React.Fragment>
    );

}
