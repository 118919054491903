export const footerContent = {

    gamaToursSection: {

        title: "GAMA TOURS",

        items: {
            home: {
                es: "Inicio",
                en: "Home",
                fr: "Page d'accueil",
                link: '/'
            },
            panama: {
                es: "Panamá",
                en: "Panamá",
                fr: "Panamá",
                link: '/panama'
            },
            tours: {
                es: "Tours",
                en: "Tours",
                fr: "Tours",
                link: '/guided-tours'
            },
            todo: {
                es: "Destinos",
                en: "Destination",
                fr: "Destination",
                link: '/trips'
            },
            contactUs: {
                es: "Contactenos",
                en: "Contact Us",
                fr: "Nous Contacter",
                link: '/contact'
            }
        }
    },

    contactUsSection: {
        title: {
            es: "CONTACTENOS",
            en: "CONTACT US",
            fr: "NOUS CONTACTER",
        }
    },

    messageForm: {
        title: {
            es: "MENSAJE",
            en: "MESSAGE",
            fr: "MESSAGE"
        }
    },
}
