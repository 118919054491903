import {PANAMA} from "../utils/Images";


const IamPanama = {

    descriptions: {
        es: [
            "Hola! Soy una pequeña franja en forma de una S acostada, justo en la América\n" +
            "Central, cuya misión es la de unir Las Américas, bañada por aguas cálidas de dos océanos, El\n" +
            "Atlántico y El Pacífico, pero extensa en biodiversidad y aventuras inolvidables.",

            "Cuento con una variedad de destinos y actividades que esperan que los conozcas. Siempre trato\n" +
            "de complacer todos los gustos, como para aquel que le gusta relajarse en playas de aguas serenas\n" +
            "y cristalinas de las Costas Caribeñas, como para aquel que va en busca de las emocionantes olas de\n" +
            "las Costas del Pacífico.",

            "En mi encontrarás contacto directo y puro con la naturaleza, pues mi fauna y flora son\n" +
            "inigualables! Además de conocer mi rica cultura, tradiciones y gastronomía, tendrás la\n" +
            "oportunidad de visitar nuestros sitios arqueológicos y admirar mi fascinante arquitectura y sus\n" +
            "visibles contrastes.",

            "Debido a mi versatilidad, trasladarte de un punto a otro del país lo lograrás en un abrir y cerrar de\n" +
            "ojos. Contarás con la oportunidad de adentrarte y conocer las diferentes comunidades de pueblos\n" +
            "indígenas originarios que me componen, así mismo podrás visitar los primeros asentamientos\n" +
            "coloniales hasta la ciudad moderna de nuestros días. ¡Soy Panamá!"
        ],

        en: [
            "Hello! I am a small narrow in the shape of a lying S, right in Central America,\n" +
            "whose mission is to unite the Americas, bathed by warm waters from two oceans, the Atlantic and\n" +
            "the Pacific, but extensive in biodiversity and adventures unforgettable.",

            "I have a variety of destinations and activities waiting for you to discover them. I always try to\n" +
            "please all tastes, both for those who like to relax on beaches with serene and crystal clear waters\n" +
            "of the Caribbean Coasts, and for those who go in search of the exciting waves of the Pacific Coasts.",

            "In me you will find direct and pure contact with nature, because my fauna and flora are\n" +
            "unmatched! In addition to knowing my rich culture, traditions and gastronomy, you will have the\n" +
            "opportunity to visit our archaeological sites and admire my fascinating architecture and its visible\n" +
            "contrasts.",

            "Due to my versatility, moving from one point to another in the country will be achieved in the\n" +
            "blink of an eye. You will have the opportunity to enter and get to know the different communities\n" +
            "of native indigenous peoples that make up me, likewise you will be able to visit the first colonial\n" +
            "settlements up to the modern city of our days. I`m Panamá!"
        ],

        fr: [
            "Bonjour! Je suis une petite bande en forme de S couché, en plein Amérique\n" +
            "centrale, dont la mission est d&#39;unir les Amériques, baignée par les eaux chaudes de deux océans,\n" +
            "l&#39;Atlantique et le Pacifique, mais riche en biodiversité et en aventures inoubliable.",

            "J&#39;ai une variété de destinations et d&#39;activités qui vous attendent pour les découvrir. J&#39;essaie\n" +
            "toujours de plaire à tous les goûts, à la fois pour ceux qui aiment se détendre sur les plages aux\n" +
            "eaux sereines et cristallines des côtes des Caraïbes, et pour ceux qui partent à la recherche des\n" +
            "vagues excitantes des côtes du Pacifique.",

            "En moi vous trouverez un contact direct et pur avec la nature, car ma faune et ma flore sont\n" +
            "inégalées ! En plus de connaître ma riche culture, mes traditions et ma gastronomie, vous aurez\n" +
            "l&#39;occasion de visiter nos sites archéologiques et d&#39;admirer mon architecture fascinante et ses\n" +
            "contrastes visibles.",

            "Grâce à ma polyvalence, le déplacement d&#39;un point à un autre du pays se fera en un clin d&#39;œil.\n" +
            "Vous aurez l&#39;opportunité d&#39;entrer et de connaître les différentes communautés de peuples\n" +
            "autochtones qui me composent, de même que vous pourrez visiter les premières colonies\n" +
            "coloniales jusqu&#39;à la ville moderne de nos jours. ¡Je sui Panamá!"

        ]
    },
    images: [
        PANAMA.panama1,
        PANAMA.panama2,
        PANAMA.panama3,
        PANAMA.panama4
    ]
}

// const PanamaInfoContent = {
//         title: {
//             es: "Información General",
//             en: "General Information",
//             fr: "Informations Générales",
//         },
//         info: [
//             { icon: name, title:{es:"Nombre Oficial", en:"Oficial Name", fr:"Nombre officiel"}, text:{es:"República de Panamá", en:"Republic of AboutPanama", fr:"République du AboutPanama"}},
//             { icon: population, title:{es:"Habitantes", en:"Population", fr:"Population"}, text:{es:"4.5 Millones", en:"4.5 Millions", fr:"4.5 Millions"} },
//             { icon: climate, title:{es:"Clima", en:"Climate", fr:"Climat"}, text:{es:"Tropical seco y lluvioso", en:"Dry and Rainy Tropical", fr:"Tropical sec et pluvieux"} },
//             { icon: temperature, title:{es:"Temperatura", en:"Temperature", fr:"Température"}, text:{es:"27ºC a 29ºC", en:"27ºC a 29ºC", fr:"27ºC a 29ºC"} },
//             { icon: surface, title:{es:"Superficie", en:"Surface Area", fr:"Surface"}, text:{es:"75517 Km2", en:"75517 Km2", fr:"75517 Km2"} },
//             { icon: capital, title:{es:"Capital", en:"Capital", fr:"Capitale"}, text:{es:"Ciudad de Panamá", en:"Panamá City", fr:"Ville de AboutPanama"} },
//             { icon: currency, title:{es:"Moneda", en:"Currency", fr:"Devise"}, text:{es:"Balboa y USD", en:"Balboa y USD", fr:"Balboa y USD"} },
//             { icon: language, title:{es:"Lengua", en:"languages", fr:"Langue"}, text:{es:"Español e Inglés", en:"Spanish & English", fr:"Espagnol et Anglais"} },
//         ]
//     }

export {IamPanama}
