export const contactUsContent = {
    alertMessage: {
        success: {
            title: {
                es: "¡Mensaje enviado correctamente!",
                en: "Message sent successfully!",
                fr: "Message envoyé avec succès!"
            },
            content: {
                es: "Le estaremos contactando muy pronto.",
                en: "We will be contacting you very soon.",
                fr: "Nous vous contacterons très prochainement."
            }
        },
        error: {
            title: {
                es: "Error al enviar mensaje...",
                en: "Error sending message ...",
                fr: "Erreur lors de l'envoi du message..."
            },
            content: {
                es: "Por favor, inténtelo más tarde.",
                en: "Please try again later.",
                fr: "Veuillez réessayer plus tard."
            }
        },
        loading: {
            es: "Enviando...",
            en: "Sending...",
            fr: "Envoi en cours..."
        }
    },

    emailTemplate: {
        name: {
            es: "Saludos, mi nombre es:",
            en: "Greetings, my name is:",
            fr: "Bonjour, je m'appelle:"
        },
        message: {
            es: "Mi mensaje:",
            en: "My message:",
            fr: "Mon message:"
        },
        email: {
            es: "Favor contactarme al siguiente correo:",
            en: "Please contact me at the following email:",
            fr: "Merci de me contacter à l'email suivant:"
        }
    },

    messageForm: {
        name: {
            label: {
                es: "Nombre",
                en: "Name",
                fr: "Nom"
            }
        },

        email: {
            label: {
                es: "E-mail",
                en: "E-mail",
                fr: "E-mail"
            }
        },

        message: {
            label: {
                es: "Mensaje",
                en: "Message",
                fr: "Message"
            }
        },

        submitButton: {
            label: {
                es: "Enviar",
                en: "Send",
                fr: "Envoyer"
            }
        },

        validation: {
            es: "Este campo es requerido",
            en: "This field is required",
            fr: "Ce champ est obligatoire"
        }
    },

    pageLayout: {
        title: {
            es: "Contactenos",
            en: "Contact Us",
            fr: "Nous contacter"
        }
    }
}
