import React, { Component } from 'react';
import {FlipCard} from "./components/FlipCard";
import {Container} from "reactstrap";
import {TripList} from "./components/TripList";
import {TripMoreInfo} from "./components/TripMoreInfo";


const TripMoreInfoPage = () => {
    return (
        <section className="section bg-light" id="blog">
            <TripMoreInfo/>
        </section>
    )
}

const Trips = () => {
    return (
        <section className="section bg-light" id="blog">
            <Container>
                <TripList/>
            </Container>
        </section>
    );
}

export {Trips, TripMoreInfoPage}
