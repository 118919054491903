import React, {useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import {GuidedTours} from "../../../content/guidedTours";
import {TripsToDo} from "../../../content/tripsToDo";
import {useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../assets/styles/custom-cards-v2.scss"
import {TOURS} from "../../../utils/Images";


export const Tours = () => {

    const PageGlosary = {
        title: {
            es: "Tours Guiados",
            en: "Guided tours",
            fr: "Visites guidées"
        },
        more:{
            es: "Ver Más",
            en: "See More",
            fr: "Voir plus"
        }
    }

    const ln = useSelector((state) => state.language.selected_language);
    const navigate = useNavigate();
    const RenderTours = () => {

        const [guidedTours] = useState(TripsToDo);

        return guidedTours.tours.map((tour, idx) => {
            console.log(tour.name)
            const position = idx % 2 === 0 ? "left" : "right"
            const colPosition = position === "left" ? {offset: 0, size: 6} : {offset: 6, size: 6}
            return (
                <Col xs={12} lg={6}>
                    <div className={`blog-card ${position === "right" && "alt"}`}>
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: `url(${tour.name ? TOURS[tour.name]['portrait'][0] : null })`}}></div>
                            <ul className="details">
                                {/*<li className="author"><a href="#">John Doe</a></li>*/}
                                {/*<li className="date">Aug. 24, 2015</li>*/}
                                {/*<li className="tags">*/}
                                {/*    <ul>*/}
                                {/*        <li><a href="#">Learn</a></li>*/}
                                {/*        <li><a href="#">Code</a></li>*/}
                                {/*        <li><a href="#">HTML</a></li>*/}
                                {/*        <li><a href="#">CSS</a></li>*/}
                                {/*    </ul>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                        <div className="description">
                            <h5 className="amatic-font-stlye">{tour.title[ln]}</h5>
                            <h2>{tour.location[ln]}</h2>
                            <div className={"custom-card-description"}>
                                <p className="card-text">{tour.description[ln][0].slice(0, 260) + "..."}</p>
                            </div>
                            <div className={`card-button-sticky-${position}`}>
                                <Button onClick={() => navigate(`/guided-tours/more-info/${tour.id}`)} style={{borderColor: "#d67152", color:"#d67152", backgroundColor: "transparent"}}>{PageGlosary.more[ln]}</Button>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        })


    }

    return (
        <React.Fragment>
            <Container>
                <h1 className="amatic-font-stlye-2">{PageGlosary.title[ln]}</h1>
                <Row>
                    {RenderTours()}
                </Row>
            </Container>
        </React.Fragment>
    )

}