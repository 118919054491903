import {useSelector} from "react-redux";
import {Container, Row, Col} from "reactstrap";
import SectionTitle from "../../../components/common/section-title";
import React from "react";
import {IamPanama} from "../../../content/panama";
import {PANAMA} from "../../../utils/Images";

export const AboutPanama = () => {

    const ln = useSelector((state) => state.language.selected_language);

    const renderDescriptions = () => {
        return IamPanama.descriptions[ln].map( (description, idx) => {

            return (
                <Row style={{marginTop:"120px"}}>


                    {
                        idx % 2 === 0 ?
                            <>
                                <Col xs={12} lg={7} style={{paddingTop: "6%"}}>
                                    <p style={{textAlign:"justify"}}> {description} </p>
                                </Col>
                                <Col xs={12} lg={5}>
                                    <img style={{width:"100%"}} src={IamPanama.images[idx]}/>
                                </Col>
                            </>
                            :
                            <>
                                <Col xs={12} lg={5}>
                                    <img style={{width:"100%"}} src={IamPanama.images[idx]}/>
                                </Col>
                                <Col xs={12} lg={7} style={{paddingTop: "6%"}}>
                                    <p style={{textAlign:"justify"}}> {description} </p>
                                </Col>
                            </>
                    }
                </Row>
            )

        })
    }

    return (
        <React.Fragment>
            <section className="section bg-light" id="blog">
                <Container>

                    <h1 className="amatic-font-stlye-2">Panamá</h1>

                    <Row>

                    </Row>
                    <Col sm="12" md={{ size: 10, offset: 1 }}>

                        {renderDescriptions()}

                    </Col>


                    <SectionTitle
                        title={<h1 className={"amatic-font-stlye"}></h1>}
                        // description={<p>[------ DESCRIPCION ------]</p>}
                    />

                </Container>
            </section>
        </React.Fragment>

    )
}