// import Index1 from "../temp/Index1/Index1";
import {LandingPage} from "./pages/LandingPage";
import {Panama} from "./pages/Panama";
import {GuidedTours, TourMoreInfoPage} from "./pages/GuidedTours";
import {TripMoreInfoPage, Trips} from "./pages/Trips";

// import Index3 from "../temp/Index3/Index3";
// import Index4 from "../temp/Index4/Index4";
// import Index5 from "../temp/Index5/Index5";
// import Index6 from "../temp/Index6/Index6";
// import Index7 from "../temp/Index7/Index7";
// import Index8 from "../temp/Index8/Index8";
// import Index9 from "../temp/Index9/Index9";
// import Login from "../temp/Auth/login";
// import SignUp from "../temp/Auth/signup";
// import PasswordForget from "../temp/Auth/password_forget";
import {GeneralLayout} from "./layouts";
import React from "react";

const routes = [
  { path: "/", element: <GeneralLayout component={LandingPage}/> },
  { path: "/panama", element: <GeneralLayout component={Panama}/> },
  { path: "/guided-tours", element: <GeneralLayout component={GuidedTours}/> },
  { path: "/guided-tours/more-info/:id", element: <GeneralLayout component={TourMoreInfoPage}/> },
  { path: "/trips", element: <GeneralLayout component={Trips}/> },
  { path: "/trips/more-info/:id", element: <GeneralLayout component={TripMoreInfoPage}/> },

    //Auth
  // { path: "/signup", component: SignUp },
  // { path: "/login", component: Login },
  // { path: "/password_forget", component: PasswordForget },
  //
  // { path: "/index9", component: Index9 },
  // { path: "/index8", component: Index8 },
  // { path: "/index7", component: Index7 },
  // { path: "/index6", component: Index6 },
  // { path: "/index5", component: Index5 },
  // { path: "/index4", component: Index4 },
  // { path: "/index3", component: Index3 },
  // { path: "/", element: <GeneralLayout component={LandingPage}/>},
];

export default routes;
