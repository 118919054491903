import React from "react";
import {Col, Container, Row} from "reactstrap";
import SectionTitle from "../../../components/common/section-title";
import BlogBox from "../../../components/Blog/blog-box";
import {useSelector} from "react-redux";
import content from "./content";

export const AboutUs = () =>{

    const ln = useSelector((state) => state.language.selected_language);
    const aboutUs = content.aboutUs.about_us;
    const mission = content.aboutUs.mission;
    const vision = content.aboutUs.vision;

    const images = `${process.env.PUBLIC_URL}/assets/images`

    return(
        <React.Fragment>
            <section className="section bg-orange" id="blog">
                <Container>

                    <SectionTitle
                        title={<h1 className={"amatic-font-stlye"}>{aboutUs.title[ln]}</h1>}
                        description={<p className={"custom-justify"} style={{color:"white"}}>{aboutUs.content[ln]}</p>}
                    />

                    <Row style={{marginTop:"8vh"}}>
                        <Col xs="12" sm="12" xl="6" style={{textAlign:"center"}}>
                            <SectionTitle
                                title={
                                    <>
                                        <img style={{width:"150px"}} src={`${images}/landing-page/mision.png`}/>
                                        <h3 className={"amatic-font-stlye"}>{mission.title[ln]}</h3>
                                    </>

                                }
                                description={<p className={"custom-justify"} style={{color:"white"}}>{mission.description[ln]}</p>}
                            />

                        </Col>

                        <Col xs="12" sm="12" xl="6" style={{textAlign:"center"}}>

                            <SectionTitle
                                title={
                                <>
                                    <img style={{width:"150px"}} src={`${images}/landing-page/vision.png`}/>
                                    <h3 className={"amatic-font-stlye"}>{vision.title[ln]}</h3>
                                </>

                                }
                                description={<p className={"custom-justify"} style={{color:"white"}}>{vision.description[ln]}</p>}
                            />
                        </Col>
                    </Row>

                </Container>
            </section>
        </React.Fragment>
    )


}