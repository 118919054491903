const TOURS = {

    casco: {

        portrait: [
            require('../assets/img/tours/casco_1.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/casco_1.jpg'),
            require('../assets/img/tours/casco_2.jpg'),
            require('../assets/img/tours/cerveza_1.jpg'),
        ]
    },

    gamboa_causeway: {

        portrait: [
            require('../assets/img/tours/gamboa_1.jpg'),

        ],
        landscape: [
            require('../assets/img/tours/gamboa_1.jpg'),
            require('../assets/img/tours/gamboa_2.jpg'),
            require('../assets/img/tours/causeway_1.jpg'),
        ]

    },

    miraflor_casco: {

        portrait: [
            require('../assets/img/tours/miraflor_1.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/casco_1.jpg'),
        ]


    },

    fuerte_san_lorenzo: {
        portrait: [
            require('../assets/img/tours/fuerte_san_lorenzo_1.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/fuerte_san_lorenzo_1.jpg'),
        ]
    },

    panama_viejo: {

        portrait: [
            require('../assets/img/tours/panama_viejo_1.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/panama_viejo_2.jpg'),
            require('../assets/img/tours/reprosa_1.jpg'),
        ]
    },

    parque_metropolitano: {

        portrait: [
            require('../assets/img/tours/mi_pueblito_1.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/mi_pueblito_1.jpg'),
            require('../assets/img/tours/parque_metropolitano_1.jpg'),
            require('../assets/img/tours/causeway_1.jpg'),
        ]

    },

    san_blas: {

        portrait: [
            require('../assets/img/tours/san_blas_1.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/san_blas_1.jpg'),
        ]


    },

    isla_taboga: {

        portrait: [
            require('../assets/img/tours/isla_taboga_1.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/isla_taboga_3.jpg'),
            require('../assets/img/tours/isla_taboga_2.jpg'),
        ]


    },

    albrook_mall: {

        portrait: [
            require('../assets/img/tours/albrook_mall_1.JPG'),
        ],
        landscape: [
            require('../assets/img/tours/albrook_mall_2.jpg'),
            require('../assets/img/tours/albrook_mall_3.jpg'),
        ]


    },


    embera: {

        portrait: [
            require('../assets/img/tours/embera_1.jpg'),
            require('../assets/img/tours/embera_13.jpg'),
            require('../assets/img/tours/embera_11.jpg'),
            require('../assets/img/tours/embera_10.jpg'),
        ],
        landscape: [
            require('../assets/img/tours/embera_14.jpg'),
            require('../assets/img/tours/embera_4.jpg'),
            require('../assets/img/tours/embera_6.jpg'),
            require('../assets/img/tours/embera_7.jpeg'),
            require('../assets/img/tours/embera_9.jpg'),
        ]


    }
}

const TRIPS = {

    pedasi: {
        portrait: [
            require('../assets/img/trips/pedasi_playa_venao_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/pedasi_isla_iguana_1.jpg'),
            require('../assets/img/trips/pedasi_pueblo_1.jpg'),
            require('../assets/img/trips/pedasi_resort_town_1.jpg'),
        ]
    },

    colon:{
        portrait: [
            require('../assets/img/trips/colon.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/tunel_del_amor_colon.jpg'),
            require('../assets/img/trips/aguas_clara.jpg'),
            require('../assets/img/trips/portobelo.jpg'),
            require('../assets/img/trips/san_lorenzo.jpg'),
            require('../assets/img/trips/venas azules from el trip de jenny.jpg'),
        ]
    },

    isla_perlas: {
        portrait: [
            require('../assets/img/trips/isla_de_las_perlas_1.jpg'),
        ],
        landscape: []
    },
    teatro_nacional: {

        portrait: [
            require('../assets/img/trips/teatro_nacional_1.jpg'),
        ],
        landscape: []


    },
    iglesias_casco_antiguo: {

        portrait: [
            require('../assets/img/trips/inglesia_casco_1.jpg'),
        ],
        landscape: []


    },
    cerveza_artesanal: {

        portrait: [
            require('../assets/img/trips/craft_beer_1.jpg'),
        ],
        landscape: []


    },
    gamboa: {

        portrait: [
            require('../assets/img/trips/gamboa_1.jpg'),
        ],
        landscape: []


    },
    hotel_gamboa: {
        portrait: [
            require('../assets/img/trips/hotel_gamboa_1.jpg'),
        ],
        landscape: []


    },
    canopy_gamboa: {
        portrait: [
            require('../assets/img/trips/cannopy_gamboa_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/cannopy.jpg')
        ]


    },
    teleferico_gamboa: {
        portrait: [
            require('../assets/img/trips/teleferico_santuario_gamboa_1.jpg'),
        ],
        landscape: []

    },
    tunel_amor_gamboa: {
        portrait: [
            require('../assets/img/trips/tunel_del_amor_colon.jpg'),
        ],
        landscape: []

    },
    biomuseo: {
        portrait: [
            require('../assets/img/trips/biomuseo_1.jpg'),
        ],
        landscape: []

    },
    guna_yala: {
        portrait: [
            require('../assets/img/trips/guna_1.jpg'),
        ],
        landscape: []

    },
    taboga: {
        portrait: [
            require('../assets/img/trips/taboga_1.jpg'),
        ],
        landscape: []

    },
    playa_venao: {
        portrait: [
            require('../assets/img/trips/playa_venao.jpg'),
        ],
        landscape: []

    },
    el_valle_de_anton: {
        portrait: [
            require('../assets/img/trips/valle_de_anton_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/valle_de_anton_2.jpg'),
            require('../assets/img/trips/cannopy.jpg'),
            require('../assets/img/trips/valle de anton 1 (2).jpg'),
        ]


    },
    fuerte_san_lorenzo: {
        portrait: [
            require('../assets/img/trips/fuerte_san_lorenzo_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/fuerte_san_lorenzo_2.jpg'),
        ]


    },
    boca_chica: {
        portrait: [
            require('../assets/img/trips/boca_chica_hospedaje_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/boca_chica_1.jpg'),
            require('../assets/img/trips/boca_chica_ballenas_1.jpg'),
            require('../assets/img/trips/CH_BocaChica-Isla-Bolanos-71.jpg'),
            require('../assets/img/trips/cala mia boca chica.jpg'),
            require('../assets/img/trips/boca brava en boca chica.jpg'),
        ]

    },
    coiba: {
        portrait: [
            require('../assets/img/trips/coiba.jpg'),
        ],
        landscape: []

    },
    santa_fe_veraguas: {
        portrait: [],
        landscape: []
    },
    esclusas_aguas_claras: {
        portrait: [
            require('../assets/img/trips/esclusas_aguas_claras.jpg'),
        ],
        landscape: []

    },
    santa_catalina: {
        portrait: [
            require('../assets/img/trips/santa_catalina.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/santa_catalina.jpg'),
            require('../assets/img/trips/panama-parque-nacional-coiba-02.jpg'),
            require('../assets/img/trips/santa catalina 2.jpg'),
            require('../assets/img/trips/Selina-Venao-ref-revista panorama.jpg'),
        ]

    },
    parque_metropolitano: {
        portrait: [
            require('../assets/img/trips/parque_metropolitano_1.jpg'),
        ],
        landscape: []

    },
    esclusas_miraflor: {
        portrait: [
            require('../assets/img/trips/esclusas_miraflores_1.png'),
        ],
        landscape: []

    },
    esclusas_gatun: {
        portrait: [
            require('../assets/img/trips/esclusas_gatun.jpg'),
        ],
        landscape: []

    },
    boquete: {
        portrait: [
            require('../assets/img/trips/boquete_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/boquete_1.jpg'),
            require('../assets/img/trips/canopy 4 pahoy.jpg'),
            require('../assets/img/trips/hotel garden inn.jpg'),
            require('../assets/img/trips/quetzal.jpg'),
        ]

    },
    catamaran: {
        portrait: [
            require('../assets/img/trips/catamaran_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/IMG-20211028-WA0000 (1).jpg'),
            require('../assets/img/trips/catamaran_1.jpg'),
        ]

    },
    volcan_tierras_altas: {
        portrait: [
            require('../assets/img/trips/volcan.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/cerro punta-embajada de panama en francia.jpg'),
            require('../assets/img/trips/CjljPKFWgAALZ4D.jpg'),
            require('../assets/img/trips/volcan baru 2.jpg'),
        ]


    },
    fuerte_portobelo: {
        portrait: [
            require('../assets/img/trips/portobelo_1.jpg'),
        ],
        landscape: []

    },
    venas_azules: {
        portrait: [
            require('../assets/img/trips/venas_azules_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/venas_azules_2.jpg'),
        ]


    },
    archipielago_san_blas: {
        portrait: [
            require('../assets/img/trips/san_blas_archipielago.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/guna yala isla 1.jpg'),
            require('../assets/img/trips/GY-GunaYala-barco undido isla perro chico.jpg'),
            require('../assets/img/trips/aridup.jpg'),
            require('../assets/img/trips/bungalu en cayo limones by airbnb.jpg'),
            require('../assets/img/trips/san_blas_archipielago_1.jpg'),
        ]

    },
    panama_vieja: {
        portrait: [
            require('../assets/img/trips/panama_la_vieja.jpg'),
        ],
        landscape: []

    },
    azuero: {
        portrait: [
            require('../assets/img/trips/azuero_pollera_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/CUBITA 2.jpg'),
            require('../assets/img/trips/ISIDRO 4.jpg'),
            require('../assets/img/trips/MUSEO 2.jpg')
        ]

    },
    bocas_del_toro: {
        portrait: [
            require('../assets/img/trips/hotel_azul_paradise_bocas_del_toro.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/bocas_del_toro_1.jpg'),
            require('../assets/img/trips/boca_cayo_coral.jpg'),
        ]

    },
    causeway: {
        portrait: [
            require('../assets/img/trips/causeway_1.jpg'),
        ],
        landscape: []

    },
    isla_mamey: {
        portrait: [
            require('../assets/img/trips/isla_mamey_1.jpg'),
        ],
        landscape: []

    },
    cerro_ancon: {
        portrait: [
            require('../assets/img/trips/cerro_ancon_1.jpg'),
        ],
        landscape: []

    },
    embera: {
        portrait: [
            require('../assets/img/trips/embera_1.PNG'),
        ],
        landscape: []

    },

    san_blas: {
        portrait: [
            require('../assets/img/trips/san_blas_1.png'),
        ],
        landscape: [

        ]

    },

    mi_pueblito: {
        portrait: [
            require('../assets/img/trips/mi_pueblito_1.jpg'),
        ],
        landscape: []

    },

    railway: {
        portrait: [
            require('../assets/img/trips/panama_railway_1.jpg'),
        ],
        landscape: []

    },

    riviera_del_pacifico: {
        portrait: [
            require('../assets/img/trips/riviera_pacifica_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/PLAYA 1.jpg'),
            require('../assets/img/trips/RESORT.jpg'),
            require('../assets/img/trips/GOLF.jpeg')
        ]

    },

    casco_antiguo: {
        portrait: [
            require('../assets/img/trips/casco_antiguo_1.jpg'),
        ],
        landscape: [
            require('../assets/img/trips/casco_antiguo_2.jpg'),
            require('../assets/img/trips/casco_antiguo_3.jpg'),
        ]


    },

}

const PANAMA = {
    panama1: require('../assets/img/panama/panama_1.jpg'),
    panama2: require('../assets/img/panama/panama_2.jpg'),
    panama3: require('../assets/img/panama/panama_3.jpg'),
    panama4: require('../assets/img/panama/panama_4.jpg')
}

export {TRIPS, TOURS, PANAMA};