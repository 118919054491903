import React, {useState, useEffect} from "react";
import {Container, Row, Col, Card, CardTitle, CardBody, CardText} from "reactstrap";
import {Carousel} from "react-responsive-carousel";
import {useParams} from "react-router-dom";
import {banner} from "../../LandingPage/components/content/banner";
import {useSelector} from "react-redux";
import {GiClothes, GiSandsOfTime, GiDirectionSigns} from "react-icons/gi";
import {BsFillBookmarkStarFill, BsFillBookmarkCheckFill, BsFillBookmarkXFill} from "react-icons/bs";
import {TripsNew} from "../../../content/tripsNew";
import {TRIPS} from "../../../utils/Images";

export const TripMoreInfo = () => {

    const PageGlosary = {
        duration: {
            es: "Duración",
            en: "Duration",
            fr: "Durée"
        },
        outfit: {
            es: "Vestimenta",
            en: "Outfit",
            fr: "Tenue"
        },
        location: {
            es: "Ubicación",
            en: "Location",
            fr: "Emplacement"
        },
        activities: {
            es: "Actividades",
            en: "Activities",
            fr: "Activités"
        },
        includes: {
            es: "Incluye",
            en: "Includes",
            fr: "Il comprend"
        },
        not_included: {
            es: "No Incluye",
            en: "Does not include",
            fr: "N'inclut pas"
        }
    }

    const {id} = useParams()
    const [trip, setTrip] = useState(TripsNew.find(trip => trip.id === id))
    console.log(trip);
    const ln = useSelector((state) => state.language.selected_language);

    const renderDescription = () => {
        return trip.description[ln].map(description => {

            if (typeof description === 'object') {

                if (description.type === "list") {
                    return (
                        <Col xs={12} xl={12}>
                            <p className="custom-description-black">{description.header}</p>
                            <ul>
                                {description.content.map(item => {
                                    return <li><p className="custom-description-black">{item}</p></li>
                                })}
                            </ul>
                        </Col>
                    )
                }

            } else {
                return (
                    <Col xs={12} xl={12}>
                        <p className="custom-description-black">{description}</p>
                    </Col>
                )
            }


        })
    }

    // const renderActivities = () =>{
    //     return trip.activity[ln].map(activity =>{
    //         return <li>{activity}</li>
    //     })
    // }

    // const renderIncludes = () =>{
    //     return trip.includes[ln].map(item =>{
    //         return <li>{item}</li>
    //     })
    // }

    // const renderNotIncluded = () =>{
    //     return trip.not_included[ln].map(item =>{
    //         return <li>{item}</li>
    //     })
    // }

    return (
        <React.Fragment>
            <Container>

                <h1 className="amatic-font-stlye-2">{trip.title[ln]}</h1>

                <Carousel showArrows={true} showThumbs={false}>
                    {
                        TRIPS[trip.name]['landscape'].map(img => {
                            return (
                                <div style={{maxHeight:"500px"}}>
                                    <img style={{maxHeight:"500px", objectFit:"cover"}} src={img} />
                                </div>
                            )
                        })
                    }
                </Carousel>

                {/*<Row className={"custom-description-black"} style={{marginTop:"30px"}}>*/}

                {/*    <Col xs={12} xl={4} style={{marginBottom:"30px"}} c>*/}
                {/*        <p style={{textAlign:"center"}}><GiSandsOfTime size={40}/>{PageGlosary.duration[ln]}</p>*/}
                {/*        <hr style={{  border:"1px", width:"70%", margin:"auto"}}/>*/}
                {/*        <p style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginTop: "5px"}}>{tour.time[ln]}</p>*/}
                {/*    </Col>*/}
                {/*    */}
                {/*    <Col xs={12} xl={4} style={{marginBottom:"30px"}}>*/}
                {/*        <p style={{textAlign:"center"}}><GiClothes size={40}/>{PageGlosary.outfit[ln]}</p>*/}
                {/*        <hr style={{  border:"1px", width:"70%", margin:"auto"}}/>*/}
                {/*        <p style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginTop: "5px"}}>{tour.cloths[ln]}</p>*/}
                {/*    </Col>*/}


                {/*    <Col xs={12} xl={4} style={{marginBottom:"30px"}}>*/}
                {/*        <p style={{textAlign:"center"}}><GiDirectionSigns size={40}/>{PageGlosary.location[ln]}</p>*/}
                {/*        <hr style={{  border:"1px", width:"70%", margin:"auto"}}/>*/}
                {/*        <p style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginTop: "5px"}}>{tour.location[ln]}</p>*/}
                {/*    </Col>*/}

                {/*</Row>*/}

                <Row style={{marginTop: "20px"}}>
                    {renderDescription()}
                </Row>

                {/*<Row style={{marginTop:"20px"}}>*/}

                {/*    <Col>*/}
                {/*        <Card style={{borderRadius:"20px", height:"400px"}}>*/}
                {/*            <CardBody>*/}
                {/*                <CardTitle tag="h5">*/}
                {/*                    <h3 style={{textAlign:"center"}} className="amatic-font-stlye"><BsFillBookmarkCheckFill color={"#32aaaa"}/> {PageGlosary.includes[ln]}</h3>*/}
                {/*                </CardTitle>*/}
                {/*                <CardText>*/}
                {/*                    <ul>{renderIncludes()}</ul>*/}
                {/*                </CardText>*/}
                {/*            </CardBody>*/}
                {/*        </Card>*/}
                {/*    </Col>*/}

                {/*    { trip.not_included ?*/}
                {/*        <Col>*/}
                {/*            <Card style={{borderRadius:"20px", height:"400px"}}>*/}
                {/*                <CardBody>*/}
                {/*                    <CardTitle tag="h5">*/}
                {/*                        <h3 style={{textAlign:"center"}} className="amatic-font-stlye"><BsFillBookmarkXFill color={"#d87253"}/> {PageGlosary.not_included[ln]}</h3>*/}
                {/*                    </CardTitle>*/}
                {/*                    <CardText>*/}
                {/*                        <ul>{renderNotIncluded()}</ul>*/}
                {/*                    </CardText>*/}
                {/*                </CardBody>*/}
                {/*            </Card>*/}
                {/*        </Col>*/}
                {/*        :*/}
                {/*        null*/}
                {/*    }*/}

                {/*</Row>*/}

            </Container>
        </React.Fragment>

    )

}