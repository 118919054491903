import React, {Component} from "react";
import {Col, Row, Container, Button} from "reactstrap";
import {FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaMapMarkerAlt} from "react-icons/fa"

class SocialMedia extends Component {


    render() {
        return (
            <Row>
                <Col xs="2" lg="4"></Col>
                <Col xs="8" lg="4">
                    <Row>
                        <Col>
                            <p style={{textAlign: 'center'}}>
                                <Button type="primary"
                                        size={'large'} style={{
                                    backgroundColor: 'rgba(255,255,255,0)',
                                    borderColor: 'white',
                                    borderWidth: '2px',
                                }} shape="circle"
                                        icon={<FaFacebookF style={{fontSize: '24px', color: 'white'}}/>}/>
                            </p>
                        </Col>
                        <Col>
                            <p style={{textAlign: 'center'}}>
                                <Button type="primary"
                                        size={'large'} style={{
                                    backgroundColor: 'rgba(255,255,255,0)',
                                    borderColor: 'white',
                                    borderWidth: '2px'
                                }} shape="circle"
                                        icon={<FaInstagram style={{fontSize: '24px', color: 'white'}}/>}/>
                            </p>
                        </Col>
                        <Col>
                            <p style={{textAlign: 'center'}}>
                                <Button type="primary" size={'large'} style={{
                                    backgroundColor: 'rgba(255,255,255,0)',
                                    borderColor: 'white',
                                    borderWidth: '2px'
                                }} shape="circle"
                                        icon={<FaLinkedinIn style={{fontSize: '24px', color: 'white'}}/>}/>
                            </p>
                        </Col>
                        <Col>
                            <p style={{textAlign: 'center'}}>
                                <Button type="primary" size={'large'} style={{
                                    backgroundColor: 'rgba(255,255,255,0)',
                                    borderColor: 'white',
                                    borderWidth: '2px'
                                }} shape="circle"
                                        icon={<FaYoutube style={{fontSize: '24px', color: 'white'}}/>}/>
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col xs="2" lg="4"></Col>
            </Row>
        )
    }
}

export default SocialMedia;
