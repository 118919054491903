import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Col, FormGroup, Row, Label, Input, Button, Alert} from "reactstrap";
import {Form, Collapse} from 'reactstrap';
import {contactUsContent} from "../../../content/contactUs";
import toast, {Toaster} from 'react-hot-toast';
import axios from "axios";

const {Panel} = Collapse;


const SendMailForm = (props) => {

    let options = {};
    options = {
        place: 'tl',
        message: (
            <div>
                <div>
                    Welcome to <b>Now UI Dashboard React</b> - a beautiful freebie for every web developer.
                </div>
            </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7
    }

    const language = useSelector((state) => state.language.selected_language);
    const [location, setLocation] = useState("all");
    // const [form] = Form.useForm();

    useEffect(() => {

    }, [props?.footer])


    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: props.footer ? 10 : 8},
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: e.target.name.value,
            email: e.target.email.value,
            message: e.target.message.value
        }

        const headers = {
            'Access-Control-Allow-Origin': '*',
        };

        toast.promise(
            axios.post(
                "https://vllkkztef22k2gugalbhay4o7i0nseqm.lambda-url.us-west-2.on.aws",
                data,
                {headers}), {
                loading: contactUsContent.alertMessage.loading[language],
                success: contactUsContent.alertMessage.success.content[language],
                error: contactUsContent.alertMessage.error.content[language],
            },
            {
                duration: 4000,
            });

    }

    const emailTemplate = ({name, email, message}) => {
        return (
            `<html><head></head><body><h3>${contactUsContent.emailTemplate.name[language]} ${name}</h3><p>${contactUsContent.emailTemplate.message[language]} ${message}</p> <hr/> <h4>${contactUsContent.emailTemplate.email[language]} <a href="#">${email}</a></h4> </body></html>`
        )
    }

    const sendMail = async (e) => {


        // const options = {
        //     from: "panama@gamatourspty.com",
        //     to: "panama@gamatourspty.com",
        //     subject: `Mensaje de contacto - ${e.name}`,
        //     content: emailTemplate(e),
        // };
        //
        // sesMail.sendEmail(options, (error, data) => {
        //     if (data.success) {
        //         toast.success(contactUsContent.alertMessage.success.content[language]);
        //
        //     } else {
        //         toast.error(contactUsContent.alertMessage.error.content[language]);
        //     }
        // })


    }


    return (

        <React.Fragment>
            <Toaster/>
            {/*{!props.footer ?*/}
            {/*    <Row>*/}
            {/*        <Col>*/}
            {/*            <div className="custom-title text-center" style={{marginTop: "50px"}}>*/}
            {/*                <h1>*/}
            {/*                    {contactUsContent.pageLayout.title[language]}*/}
            {/*                </h1>*/}
            {/*            </div>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*    :*/}
            {/*    null*/}
            {/*}*/}

            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                            {/*<Label for="name" sm={4}>{contactUsContent.messageForm.name.label[language]}</Label>*/}
                            <Col>
                                <Input bsSize="sm" placeholder={contactUsContent.messageForm.name.label[language]} type="text" name="name" id="name"/>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            {/*<Label for="email" sm={4}>{contactUsContent.messageForm.email.label[language]}</Label>*/}
                            <Col>
                                <Input bsSize="sm" placeholder={contactUsContent.messageForm.email.label[language]} type="email" b name="email" id="email"/>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            {/*<Label for="message" sm={4}>{contactUsContent.messageForm.message.label[language]}</Label>*/}
                            <Col>
                                <Input bsSize="sm" placeholder={contactUsContent.messageForm.message.label[language]} type="textarea" name="message" id="message"/>
                            </Col>
                        </FormGroup>

                        <Button type="submit">{contactUsContent.messageForm.submitButton.label[language]}</Button>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default SendMailForm;
