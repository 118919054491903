import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

class BlogBox extends Component {
  render() {
    const {info, ln} = this.props;
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="blog-box mt-4">
            {/*<img*/}
            {/*  src={}*/}
            {/*  className="img-fluid rounded"*/}
            {/*  alt=""*/}
            {/*/>*/}
            <div>
              <h5 className="mt-4 text-muted">{}</h5>
              <h4 className="mt-3">
                <Link to={"#"} className="blog-title">
                  {" "}
                  {info.title[ln]}{" "}
                </Link>
              </h4>
              <p className="text-muted custom-justify">{info.content[ln]}</p>
              <div className="mt-3">
                {/*<Link to={"#"} className="read-btn">*/}
                {/*  Read More <i className="mdi mdi-arrow-right"></i>*/}
                {/*</Link>*/}
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default BlogBox;
