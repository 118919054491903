import React, {Component, useEffect} from "react";
import './App.css';
import routes from "./routes";
import {Route, Routes, BrowserRouter as Router} from "react-router-dom";
import store from "./redux/store";
import {setLanguage} from "./redux/actions/languageAction";
import ScrollToTop from "./utils/ScrollToTop";

const App = () => {

    useEffect(() => {
        store.dispatch(
            setLanguage()
        )
    }, []);

    return (
        <React.Fragment>
            <Router>
                <ScrollToTop/>
                <Routes>
                    {routes.map((route, idx) => (
                        <Route path={route.path} element={route.element} key={idx}/>
                    ))}
                </Routes>
            </Router>
        </React.Fragment>
    );
}

export default App;
