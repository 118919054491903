// import name from "*.png";
// import population from "*.png";
// import climate from "*.png";
// import temperature from "*.png";
// import surface from "*.png";
// import capital from "*.png";
// import currency from "*.png";
// import language from "*.png";

export const PanamaInfoContent = {
    title: {
        es: "Información General",
        en: "General Information",
        fr: "Informations Générales",
    },
    info: [
        { icon: 'assets/images/panama/name.png', title:{es:"Nombre Oficial", en:"Oficial Name", fr:"Nombre officiel"}, text:{es:"República de Panamá", en:"Republic of AboutPanama", fr:"République du AboutPanama"}},
        { icon: 'assets/images/panama/population.png', title:{es:"Habitantes", en:"Population", fr:"Population"}, text:{es:"4.5 Millones", en:"4.5 Millions", fr:"4.5 Millions"} },
        { icon: 'assets/images/panama/climate.png', title:{es:"Clima", en:"Climate", fr:"Climat"}, text:{es:"Tropical seco y lluvioso", en:"Dry and Rainy Tropical", fr:"Tropical sec et pluvieux"} },
        { icon: 'assets/images/panama/temperature.png', title:{es:"Temperatura", en:"Temperature", fr:"Température"}, text:{es:"27ºC a 29ºC", en:"27ºC a 29ºC", fr:"27ºC a 29ºC"} },
        { icon: 'assets/images/panama/surface.png', title:{es:"Superficie", en:"Surface Area", fr:"Surface"}, text:{es:"75517 Km2", en:"75517 Km2", fr:"75517 Km2"} },
        { icon: 'assets/images/panama/capital.png', title:{es:"Capital", en:"Capital", fr:"Capitale"}, text:{es:"Ciudad de Panamá", en:"Panamá City", fr:"Ville de AboutPanama"} },
        { icon: 'assets/images/panama/currency.png', title:{es:"Moneda", en:"Currency", fr:"Devise"}, text:{es:"Balboa y USD", en:"Balboa y USD", fr:"Balboa y USD"} },
        { icon: 'assets/images/panama/language.png', title:{es:"Lengua", en:"languages", fr:"Langue"}, text:{es:"Español e Inglés", en:"Spanish & English", fr:"Espagnol et Anglais"} },
    ]
}