

export const banner = {

    welcome:{
        label:{
            es: "Bienvenido a Panamá",
            en: "Welcome to Panamá",
            fr: "Bienvenue au Panamá"
        }
    },
    experience:{
        label:{
            es: "¡Vive La Experiencia!",
            en: "Live The Experience!",
            fr: "Vivez l'expérience!"
        }
    }

}