export const TripsToDo = {

    header: {
        es: "Incluye",
        en: "Includes",
        fr: "Il comprend"
    },

    tours: [
        {
            id: 1,
            time: {
                es: "8 horas",
                en: "8 hours",
                fr: "8 heures"
            },

            title: {
                es: "Miraflor - Casco",
                en: "Miraflor - Casco",
                fr: "Miraflor - Casco"
            },

            name: "miraflor_casco",
            location: {
                es: "Panamá",
                en: "Panamá",
                fr: "Panamá"
            },
            description: {
                es: ["Hará un recorrido muy interesante e histórico sobre la majestuosa obra de ingeniería y todo lo que implica esta obra, además visitará el Centro de Interpretación del Canal que contiene imágenes, objetos, relatos y explicaciones sobre esta gigantesca construcción, además hay la oportunidad de ver en un corto metraje su historia.  Te encantará."],
                en: ["You will take a very interesting and historical tour of the majestic engineering work and everything that this work implies, you will also visit the Canal Interpretation Center that contains images, objects, stories and explanations about this gigantic construction, there is also the opportunity to see in a short film his story. You'll love it."],
                fr: ["Vous ferez une visite très intéressante et historique du majestueux ouvrage d'ingénierie et de tout ce que ce travail implique, vous visiterez également le Centre d'interprétation du canal qui contient des images, des objets, des histoires et des explications sur cette gigantesque construction, il y a aussi la possibilité de voir dans un court métrage son histoire. Ça va t'enchanter."]
            },
            activity: {
                es: [
                    "Visita al Centro de Visitante de Miraflores",
                    "El Cine explicativo de la historia de construcción del Canal",
                    "Ver el engranaje y funcionamiento de las esclusas y los barcos que la traversa",
                    "Almorzar en un agradable restaurante en el Casco",
                    "Caminar por las calles de adoquines llenas de historia del Casco",
                    "Escuchar e interactuar sobre la historia y narraciones del guía certificado",
                    "Regreso al hotel"
                ],
                en: [
                    "Visit: The Miraflores Visitor Center",
                    "The explanatory cinema of the history of construction of the Canal",
                    "See the gear and operation of the locks and the ships that pass through them",
                    "Have lunch in a nice restaurant in Casco",
                    "Walk the cobblestone streets full of history in Casco",
                    "Listen and interact about the history and narrations of the certified guide",
                    "Return to your hotel"
                ],
                fr: [
                    "Visite: Centre d'accueil des visiteurs de Miraflores",
                    "Le cinéma explicatif de l'histoire de la construction du Canal",
                    "Voir l'engrenage et le fonctionnement des écluses et les navires qui les traversent",
                    "Déjeuner dans un bon restaurant à Casco" ,
                    "Promenez-vous dans les rues pavées chargées d'histoire à Casco",
                    "Écoutez et interagissez sur l'histoire et les récits du guide certifié",
                    "Retour à votre hôtel"

                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel a los sitios indicados",
                    "Guías certificado",
                    "Entradas a las diferentes actividades mencionadas",
                    "Almuerzo en el Casco"
                ],
                en: [
                    "Roundtrip transportation from the hotel to the indicated sites",
                    "Certified guides",
                    "Tickets to the different activities mentioned",
                    "Lunch in the Casco"
                ],
                fr: [
                    "Transport aller-retour de l'hôtel aux sites indiqués",
                    "Guides certifiés",
                    "Billets pour les différentes activités mentionnées",
                    "Déjeuner au Casco"
                ]
            },

            not_included:{
                es: [
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí"
                ],
                en: [
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici"
                ]
            },

            cloths: {
                es: "Cómoda y fresca",
                en: "Comfortable and fresh",
                fr: "Confortables et frais"
            }

        },
        {
            id: 2,
            time: {
                es: "8 horas",
                en: "8 hours",
                fr: "8 heures"
            },

            title: {
                es: "Comunidad Emberá",
                en: "Emberá Community",
                fr: "Communauté Embera"
            },
            name: "embera",
            location: {
                es: "Panamá",
                en: "Panamá",
                fr: "Panamá"
            },
            description: {
                es: ["Te adentrarás a las comunidades auténticas de Panamá, en una emocionante aventura en un cayuco dirigido por un miembro de la comunidad sobre el Rio Chagres, hasta alcanzar la comunidad, donde un pueblo entero te dará la bienvenida con su música y danza."],
                en: ["You will enter the authentic communities of Panama, in an exciting adventure in a canoe led by a member of the community on the Chagres River, until you reach the community, where an entire town will welcome you with their music and dance."],
                fr: ["Vous entrerez dans les communautés authentiques de Panama, dans une aventure passionnante en canoë dirigée par un membre de la communauté sur la rivière Chagres, jusqu'à ce que vous atteigniez la communauté, où une ville entière vous accueillera avec sa musique et sa danse."]
            },
            activity: {
                es: [
                    "Visitar: Uno de las comunidades Emberá",
                    "Conocer sus tradiciones y cultura",
                    "Realizar un tour por sus jardines botánicos",
                    "Observar las habilidades de sus miembros en confección de tatoo",
                    "Apreciar y si desea comprar artesanía hechos por ellos",
                    "Bañarse en sus frescos ríos",
                    "Almuerzo sencillo al estilo de la comunidad basado en la pesca del día y plátanos fritos",
                    "Regreso a su hotel"
                ],
                en: [
                    "Visit: One of the Emberá communities",
                    "Learn about their traditions and culture",
                    "Take a tour of its botanical gardens",
                    "Observe the skills of its members in tattoo making",
                    "Appreciate and if you want to buy crafts made by them",
                    "Bathe in its cool rivers",
                    "Simple community-style lunch based on the catch of the day and fried plantains",
                    "Return to your hotel"
                ],
                fr: [
                    "Visite : Une des communautés Emberá",
                    "Découvrez leurs traditions et leur culture",
                    "Faites un tour dans ses jardins botaniques",
                    "Observez les compétences de ses membres en matière de tatouage",
                    "Appréciez et si vous voulez acheter de l'artisanat fabriqué par eux",
                    "Baignez-vous dans ses rivières fraîches",
                    "Déjeuner simple de style communautaire basé sur la pêche du jour et les plantains frits",
                    "Retour à votre hôtel"
                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel al puerto de embarque",
                    "Transporte sobre cayuco",
                    "Guías certificado",
                    "Tours ofrecidos en la comunidad",
                    "Almuerzo ofrecido por la comunidad"
                ],
                en: [
                    "Round trip transportation from the hotel to the embarkation port",
                    "Transport on canoe",
                    "certified guides",
                    "Tours offered in the community",
                    "Lunch offered by the community"
                ],
                fr: [
                    "Transport aller-retour de l'hôtel au port d'embarquement",
                    "Transport en canoë",
                    "guides certifiés",
                    "Tours offerts dans la communauté",
                    "Déjeuner offert par la communauté"
                ]
            },

            not_included:{
                es: [
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí"
                ],
                en: [
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici"
                ]
            },

            cloths: {
                es: "Cómoda y fresca",
                en: "Comfortable and fresh",
                fr: "Confortables et frais"
            }

        },
        {
            id: 3,
            time: {
                es: "8 horas",
                en: "8 hours",
                fr: "8 heures"
            },

            title: {
                es: "DISCOVERY – GAMBOA ISLA DE LOS MONOS—CALZADA DE AMADOR",
                en: "DISCOVERY – GAMBOA MONKEY ISLAND—AMADOR CAUSEWAY",
                fr: "DÉCOUVERTE - GAMBOA MONKEY ISLAND - AMADOR CAUSEWAY"
            },

            name: "gamboa_causeway",

            location: {
                es: "Panamá",
                en: "Panamá",
                fr: "Panamá"
            },
            description: {
                es: ["Visitará una de las áreas más boscosa y de densa vegetación tropical como lo es Discovery, el cual cuenta con un alto mirador que usted observará por encima de los árboles, tendrá la oportunidad de observar variedad de aves y mariposas de colores, y de seguro con un poco de suerte un hermoso Tucán.  En el área, muy cerca de allí, podrá hacer el tour Isla Monos y apreciar los barcos que hacen su travesía por el Canal, realmente ecológico.  Te encantará!"],
                en: ["You will visit one of the most wooded areas with dense tropical vegetation such as Discovery, which has a high viewpoint that you will observe above the trees, you will have the opportunity to observe a variety of colorful birds and butterflies, and surely with a little luck a beautiful Toucan. In the area, very close to there, you can take the Isla Monos tour and appreciate the boats that make their crossing through the Canal, truly ecological. You'll love it!"],
                fr: ["Vous visiterez l'une des zones les plus boisées avec une végétation tropicale dense telle que Discovery, qui a un point de vue élevé que vous observerez au-dessus des arbres, vous aurez l'occasion d'observer une variété d'oiseaux et de papillons colorés, et sûrement avec un peu chance un beau Toucan. Dans la zone, très proche de là, vous pouvez faire le tour de l'Isla Monos et apprécier les bateaux qui rendent leur traversée du canal vraiment écologique. Ça va t'enchanter!"]
            },
            activity: {
                es: [
                    "Visitar: Parque Natural de Discovery Rainforest",
                    "Visitar Isla de Los Monos en Gamboa",
                    "Almuerzo en el Hotel Gamboa",
                    "Un breve recorrido si el tiempo lo permite por Amador",
                    "Regreso a su hotel"
                ],
                en: [
                    "Visit: Discovery Rainforest Natural Park",
                    "Visit Monkey Island in Gamboa",
                    "Lunch at Hotel Gamboa",
                    "A brief tour if time permits through Amador",
                    "Return to your hotel"
                ],
                fr: [
                    "Visite : Découverte du Parc Naturel de la Forêt Tropicale",
                    "Visitez l'île aux singes à Gamboa",
                    "Déjeuner à l'hôtel Gamboa",
                    "Une brève visite si le temps le permet à travers Amador",
                    "Retour à votre hôtel"
                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel los lugares indicados",
                    "Entrada a Discovery",
                    "Guías certificado",
                    "Tour a Isla Mono",
                    "Almuerzo en el Hotel Gamboa",
                    "Breve recorrido a Calzada de Amador."
                ],
                en: [
                    "Round trip transportation from the hotel to the places indicated",
                    "Entry to Discovery",
                    "certified guides",
                    "Monkey Island Tour",
                    "Lunch at Hotel Gamboa",
                    "Brief tour to Calzada de Amador."
                ],
                fr: [
                    "Transport aller-retour de l'hôtel aux lieux indiqués",
                    "Entrée à la découverte",
                    "guides certifiés",
                    "Visite de l'île aux singes",
                    "Déjeuner à l'hôtel Gamboa",
                    "Brève visite à Calzada de Amador."
                ]
            },

            not_included:{
                es: [
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí"
                ],
                en: [
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici"
                ]
            },

            cloths: {
                es: "Cómoda y fresca",
                en: "Comfortable and fresh",
                fr: "Confortables et frais"
            }

        },
        {
            id: 4,
            time: {
                es: "+8 horas",
                en: "+8 hours",
                fr: "+8 heures"
            },

            title: {
                es: "CASCO/MUSEO DEL CANAL/TOUR DE LA CERVEZA",
                en: "CASCO/CANAL MUSEUM/BEER TOUR",
                fr: "CASCO/MUSÉE DU CANAL/VISITE DE LA BIÈRE"
            },

            name: "casco",

            location: {
                es: "Panamá, Casco Viejo",
                en: "Panamá, Casco Viejo",
                fr: "Panamá, Casco Viejo"
            },
            description: {
                es: [
                    "Casco Viejo:  Declarado por la Unesco como patrimonio de la humanidad.  Venga y camine por sus callejuelas de adoquines llenas de historias coloniales, aprecie sus hermosas estructuras y balcones llenos de flores que engalanan el sitio.",
                    "Museo del Canal Interoceánico: Una travesía que le hará vivir una experiencia de aprendizaje y entretenimiento acerca de la conservación, investigación y difusión de los testimonios de la historia del Canal de Panamá.",
                    "Degustación de Cerveza Artesanal en Rana Dorada en El Casco."
                ],
                en: [
                    "Old Town:Declared by UNESCO as a World Heritage Site. Come and walk through its cobblestone streets full of colonial stories, appreciate its beautiful structures and flower-filled balconies that adorn the site.",
                    "Inter oceanic Canal Museum:A journey that will make you live a learning and entertainment experience about the conservation, research and dissemination of the testimonies of the history of the Panama Canal.",
                    "Craft Beer Tasting at Rana Dorada in El Casco."
                ],
                fr: [
                    "Vieille ville:Déclaré par l'UNESCO comme site du patrimoine mondial. Venez vous promener dans ses rues pavées pleines d'histoires coloniales, appréciez ses belles structures et ses balcons fleuris qui ornent le site.",
                    "Musée du canal interocéanique :Un voyage qui vous fera vivre une expérience d'apprentissage et de divertissement sur la conservation, la recherche et la diffusion des témoignages de l'histoire du canal de Panama.",
                    "Dégustation de bière artisanale à Rana Dorada à El Casco."
                ]
            },
            activity: {
                es: [
                    "Visita al Cascos Antiguo",
                    "Visita al Museo del Canal Interoceánico",
                    "Almuerzo",
                    "Degustación de cerveza artesanal en Rana Dorada"
                ],
                en: [
                    "Visit to the Old Town",
                    "Visit to the Interoceanic Canal Museum",
                    "Lunch",
                    "Craft beer tasting at Rana Dorada"
                ],
                fr: [
                    "Visite de la vieille ville",
                    "Visite du Musée du Canal Interocéanique",
                    "Déjeuner",
                    "Dégustation de bière artisanale à Rana Dorada"
                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel a los lugares mencionados",
                    "Guías certificado",
                    "Entradas a los lugares ya indicados",
                    "Almuerzo",
                    "El primer botecito de degustación de cerveza artesanal."
                ],
                en: [
                    "Round trip transportation from the hotel to the mentioned places",
                    "certified guides",
                    "Tickets to the places already indicated",
                    "Lunch",
                    "The first craft beer tasting bottle."
                ],
                fr: [
                    "Transport aller-retour de l'hôtel aux lieux mentionnés",
                    "guides certifiés",
                    "Billets pour les lieux déjà indiqués",
                    "Déjeuner",
                    "La première bouteille de dégustation de bière artisanale."
                ]
            },

            not_included:{
                es: [
                    "Otras bebidas alcohólicas, que no sea el botecito de shot de cerveza",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí."
                ],
                en: [
                    "Other alcoholic beverages, other than the beer shot bottle",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Autres boissons alcoolisées, autres que la bouteille de bière",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici."
                ]
            },

            cloths: {
                es: "Cómoda y fresca",
                en: "Comfortable and fresh",
                fr: "Confortables et frais"
            }

        },
        {
            id: 5,
            time: {
                es: "+8 horas",
                en: "+8 hours",
                fr: "+8 heures"
            },

            title: {
                es: "PANAMA VIEJO / REPROSA / PARRILLADA",
                en: "OLD PANAMA / REPROSA / BARBECUE",
                fr: "ANCIEN PANAMA / REPROSA / BARBECUE"
            },

            name: "panama_viejo",

            location: {
                es: "Ciudad de Panamá",
                en: "Panamá City",
                fr: "Ville de Panama"
            },
            description: {
                es: [
                    "Panamá la Vieja: Sumergirse en la historia del primer asentamiento de los españoles en tierras panameñas y su constante batallas con los piratas por defenderla, hoy convertidas en ruinas que nos hablan de su pasado.",
                    "REPROSA: Dedicada a la promoción de Panamá, su historia, tradiciones culturales, su diversidad ecológica y belleza étnica. Nuestra exclusiva colección de joyas de oro y plata han ganado fama y notoriedad en ferias mundiales y exhibiciones."
                ],
                en: [
                    "Old Panama:Immerse yourself in the history of the first settlement of the Spanish in Panamanian lands and their constant battles with pirates to defend it, today converted into ruins that tell us about their past.",
                    "REPROUSE:Dedicated to the promotion of Panama, its history, cultural traditions, its ecological diversity and ethnic beauty. Our exclusive collection of gold and silver jewelry have gained fame and notoriety at world fairs and exhibitions."
                ],
                fr: [
                    "Vieux Panamá: Plongez dans l'histoire de la première colonie des Espagnols sur les terres panaméennes et de leurs batailles constantes avec les pirates pour la défendre, aujourd'hui transformées en ruines qui nous racontent leur passé.",
                    "REPRODUIRE: Dédié à la promotion du Panama, son histoire, ses traditions culturelles, sa diversité écologique et sa beauté ethnique. Notre collection exclusive de bijoux en or et en argent a acquis une renommée et une notoriété lors de foires et d'expositions mondiales."
                ]
            },
            activity: {
                es: [
                    "Visita a las ruinas de Panamá Viejo",
                    "Visita al Centro de Interpretación de Panamá Viejo",
                    "Visita al Centro de artesanía de Panamá Viejo",
                    "Almuerzo",
                    "Visita a Reprosa en Costa del Este"
                ],
                en: [
                    "Visit to the ruins of Panama Viejo",
                    "Visit to the Panama Viejo Interpretation Center",
                    "Visit to the Craft Center of Panama Viejo",
                    "Lunch",
                    "Visit to Reprosa in Costa del Este"
                ],
                fr: [
                    "Visite des ruines de Panama Viejo",
                    "Visite du centre d'interprétation de Panama Viejo",
                    "Visite du centre artisanal de Panama Viejo",
                    "Déjeuner",
                    "Visite à Reprosa sur la Costa del Este"
                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel a los lugares mencionados",
                    "Guías certificado",
                    "Entradas a los lugares ya indicados",
                    "Almuerzo",
                    "Regreso al hotel"
                ],
                en: [
                    "Round trip transportation from the hotel to the mentioned places",
                    "certified guides",
                    "Tickets to the places already indicated",
                    "Lunch",
                    "Back to the hotel"
                ],
                fr: [
                    "Transport aller-retour de l'hôtel aux lieux mentionnés",
                    "guides certifiés",
                    "Billets pour les lieux déjà indiqués",
                    "Déjeuner",
                    "Retour à l hotel"
                ]
            },

            not_included:{
                es: [
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí"
                ],
                en: [
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici"
                ]
            },

            cloths: {
                es: "Cómoda y fresca",
                en: "Comfortable and fresh",
                fr: "Confortables et frais"
            }

        },
        {
            id: 6,
            time: {
                es: "+8 horas",
                en: "+8 hours",
                fr: "+8 heures"
            },

            title: {
                es: "PARQUE METROPOLITANO / MI PUEBLITO INTERIORANO / CAUSEWAY",
                en: "METROPOLITAN PARK / MY INTERIOR PEOPLE / CAUSEWAY",
                fr: "PARC MÉTROPOLITAIN / MON PEUPLE INTÉRIEUR / CAUSEWAY"
            },

            name: "parque_metropolitano",

            location: {
                es: "Panamá",
                en: "Panamá",
                fr: "Panamá"
            },
            description: {
                es: [
                    "Parque Metropolitano:  Ubicado en el centro de la ciudad de Panamá, considerado como  uno de sus mayores pulmones, el Parque Metropolitano, cuyo objetivo es de preservar un área natural que contribuya a mantener el equilibrio entre el medio natural y su hábitat urbano en particular, a fin de prevenir la contaminación y propiciar un ambiente sano.",
                    "Mi Pueblito Interiorano:  Una réplica exacta de cómo son nuestra campiña interiorana, con sus casitas adobadas de hermosos colores, la iglesia cerca del parque, los muebles y utensilios utilizados aún por muchas familias del campo.  También contamos con El Pueblito Antillano, replica de los primeros asentamientos de caribeños, llenos de esplendor y color, llegados a Panamá en tiempo de la construcción del Canal."
                ],
                en: [
                    "Metropolitan park:Located in the center of Panama City, considered one of its largest lungs, the Metropolitan Park, whose objective is to preserve a natural area that contributes to maintaining the balance between the natural environment and its urban habitat in particular, in order to prevent pollution and promote a healthy environment.",
                    "My Inner Town: An exact replica of what our interior countryside is like, with its beautifully colored adobe houses, the church near the park, the furniture and utensils still used by many rural families. We also have El Pueblito Antillano, a replica of the first Caribbean settlements, full of splendor and color, which arrived in Panama at the time of the construction of the Canal."
                ],
                fr: [
                    "Parc métropolitain :Situé au centre de la ville de Panama, considérée comme l'un de ses plus grands poumons, le parc métropolitain, dont l'objectif est de préserver un espace naturel qui contribue à maintenir l'équilibre entre l'environnement naturel et son habitat urbain notamment, afin de prévenir la pollution et promouvoir un environnement sain.",
                    "Ma ville intérieure: Une réplique exacte de ce qu'est notre campagne intérieure, avec ses maisons en pisé joliment colorées, l'église près du parc, le mobilier et les ustensiles encore utilisés par de nombreuses familles rurales. Nous avons aussi El Pueblito Antillano, une réplique des premières colonies des Caraïbes, pleine de splendeur et de couleur, arrivée au Panama au moment de la construction du Canal."
                ]
            },
            activity: {
                es: [
                    "Visita a un sendero escogido en Parque Metropolitano",
                    "Visita a Mi Pueblito Interiorano y Mi Pueblito Antillano",
                    "Almuerzo libre en Causeway Amador"
                ],
                en: [
                    "Visit to a chosen trail in Parque Metropolitano",
                    "Visit to Mi Pueblito Interiorano and Mi Pueblito Antillano",
                    "Free lunch at Causeway Amador"
                ],
                fr: [
                    "Visite d'un sentier choisi dans le Parque Metropolitano",
                    "Visite de Mi Pueblito Interiorano et Mi Pueblito Antillano",
                    "Déjeuner libre à Causeway Amador"
                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel a los lugares mencionados",
                    "Guías certificado",
                    "Entradas a los lugares ya indicados",
                    "Transporte a Sabroso Panamá, restaurante típico panameño."
                ],
                en: [
                    "Round trip transportation from the hotel to the mentioned places",
                    "certified guides",
                    "Tickets to the places already indicated",
                    "Transportation to Sabroso Panama, typical Panamanian restaurant."
                ],
                fr: [
                    "Transport aller-retour de l'hôtel aux lieux mentionnés",
                    "guides certifiés",
                    "Billets pour les lieux déjà indiqués",
                    "Transport à Sabroso Panama, restaurant panaméen typique."
                ]
            },

            not_included:{
                es: [
                    "Almuerzo",
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí"
                ],
                en: [
                    "Lunch",
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Déjeuner",
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici"
                ]
            },

            cloths: {
                es: "Cómoda y fresca",
                en: "Comfortable and fresh",
                fr: "Confortables et frais"
            }

        },
        {
            id: 7,
            time: {
                es: "+8 horas",
                en: "+8 hours",
                fr: "+8 heures"
            },

            title: {
                es: "DAYPASS EN SAN BLAS",
                en: "DAYPASS IN SAN BLAS",
                fr: "PASS JOURNALIER SAN BLAS"
            },

            name: "san_blas",
            location: {
                es: "Comarca de San Blas",
                en: "Region of San Blas",
                fr: "Région de San Blas"
            },
            description: {
                es: ["Compuesto por 365 islas, una más bella que la otra, administradas por la comarca indígena Guna.  Es un verdadero paraíso en la tierra, gozará de aguas transparentes y arenas blancas y brillantes, a donde podrá bucear y hacer snorkel que le permitirán observar peces tropicales, estrellas de mar,  y otras especies marinas, sin dificultades para hacerlo.  Quedará fascinado! "],
                en: ["Composed of 365 islands, one more beautiful than the other, administered by the Guna indigenous region. It is a true paradise on earth, you will enjoy transparent waters and bright white sands, where you can dive and snorkel that will allow you to observe tropical fish, starfish, and other marine species, without difficulties to do so. You will be fascinated!"],
                fr: ["Composé de 365 îles plus belles les unes que les autres, administrées par la région indigène Guna. C'est un véritable paradis sur terre, vous profiterez d'eaux transparentes et de sable blanc brillant, où vous pourrez plonger et faire de la plongée avec tuba qui vous permettront d'observer des poissons tropicaux, des étoiles de mer et d'autres espèces marines, sans difficulté. Vous serez fasciné!"]
            },
            activity: {
                es: [
                    "Snorkel",
                    "Nado libre en hermosas playas y piscina natural",
                    "Almuerzo en la isla",
                    "Tours a conocer otras islas del lugar",
                    "Regreso a su hotel"
                ],
                en: [
                    "Snorkeling",
                    "Free swim in beautiful beaches and natural pool",
                    "Lunch on the island",
                    "Tours to know other islands of the place",
                    "Return to your hotel"
                ],
                fr: [
                    "plongée en apnée",
                    "Baignade gratuite dans de belles plages et piscine naturelle",
                    "déjeuner sur l'île",
                    "Tours pour connaître d'autres îles de l'endroit",
                    "Retour à votre hôtel"
                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel a la Comarca en carretera de cordilleras",
                    "Embarcación en bote hasta el destino",
                    "Guías certificado",
                    "Tours a los lugares ofrecidos",
                    "Almuerzo en restaurante rustico de la isla"
                ],
                en: [
                    "Roundtrip transportation from the hotel to the region on the mountain range road",
                    "Embarkation by boat to the destination",
                    "certified guides",
                    "Tours to the places offered",
                    "Lunch in a rustic restaurant on the island"
                ],
                fr: [
                    "Transport aller-retour de l'hôtel à la région par la route des montagnes",
                    "Embarquement en bateau vers la destination",
                    "guides certifiés",
                    "Visites des lieux proposés",
                    "Déjeuner dans un restaurant rustique de l'île"
                ]
            },

            not_included:{
                es: [
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí",
                    "Impuesto de entrada a la comarca, considere que el costo para extranjeros de 20.00 por persona"
                ],
                en: [
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here",
                    "Entrance tax to the region, consider that the cost for foreigners of 20.00 per person"
                ],
                fr: [
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici",
                    "Taxe d'entrée dans la région, considérez que le coût pour les étrangers de 20,00 par personne"
                ]
            },

            cloths: {
                es: "Vestido de baño, cómoda y fresca",
                en: "Bathing suit, comfortable and fresh",
                fr: "Maillot de bain, confortable et frais"
            }

        },
        {
            id: 8,
            time: {
                es: "+8 horas",
                en: "+8 hours",
                fr: "+8 heures"
            },

            title: {
                es: "FUERTE SAN LORENZO / AGUAS CLARAS",
                en: "FORT SAN LORENZO / CLEAR WATERS",
                fr: "FORT SAN LORENZO / EAU CLAIRE"
            },

            name: "fuerte_san_lorenzo",

            location: {
                es: "Ciudad de Colón",
                en: "City of Colon",
                fr: "Ville de Colon"
            },
            description: {
                es: ["El Fuerte de San Lorenzo está localizado a la entrada del río Chagres en la provincia de Colón, Panamá. Formaban el sistema defensivo para el comercio transatlántico de la Corona española.   La Esclusa de Agua Clara está ubicada cerca de la ciudad de Colón (Atlántico panameño) y forma parte de los trabajos recientes de ampliación del Canal de Panamá inaugurados el 26 de junio de 2016. Panamá railway:  Un maravilloso viaje en el confortable tren histórico se realizará entre el Canal y la Jungla panameña, donde podrá observar vistas impresionantes y memorables"],
                en: ["The Fort of San LorenzoIt is located at the entrance of the Chagres River in the province of Colón, Panama. They formed the defensive system for the transatlantic trade of the Spanish Crown. The Agua Clara Lock is located near the city of Colón (Panamanian Atlantic) and is part of the recent expansion works of the Panama Canal inaugurated on June 26, 2016. Panama railway: A wonderful trip on the comfortable historic train It will take place between the Canal and the Panamanian Jungle, where you will be able to observe impressive and memorable views"],
                fr: ["Le fort de San LorenzoIl est situé à l'entrée de la rivière Chagres dans la province de Colón, au Panama. Ils formaient le système défensif du commerce transatlantique de la couronne espagnole. L'écluse d'Agua Clara est située près de la ville de Colón (Atlantique panaméen) et fait partie des récents travaux d'agrandissement du canal de Panama inaugurés le 26 juin 2016. Chemin de fer de Panama : Un merveilleux voyage dans le confortable train historique Il se déroulera entre le canal et la jungle panaméenne, où vous pourrez observer des vues impressionnantes et mémorables"]
            },
            activity: {
                es: [
                    "Visita al Fuerte y vivir entre sus paredes la historia",
                    "Visita del Canal Ampliado en Aguas Clara y su moderna arquitectura",
                    "Cine explicativo de la obra",
                    "Almuerzo",
                    "Regreso en el histórico Ferrocarril Panama Railway"
                ],
                en: [
                    "Visit the Fort and experience history within its walls",
                    "Visit of the Expanded Canal in Aguas Clara and its modern architecture",
                    "Explanatory film of the work",
                    "Lunch",
                    "Return on the historic Panama Railway"
                ],
                fr: [
                    "Visitez le fort et découvrez l'histoire dans ses murs",
                    "Visite du canal élargi à Aguas Clara et son architecture moderne",
                    "Film explicatif de l'oeuvre",
                    "Déjeuner",
                    "Retour sur le chemin de fer historique de Panama"
                ]
            },

            includes: {
                es: [
                    "Transporte ida y vuelta desde el hotel a la ciudad de Colón",
                    "Guías certificado",
                    "Entradas a los lugares mencionados anteriormente",
                    "Almuerzo",
                    "Regreso en histórico tren"
                ],
                en: [
                    "Round trip transportation from the hotel to the city of Colón",
                    "certified guides",
                    "Tickets to the places mentioned above",
                    "Lunch",
                    "Return by historic train"
                ],
                fr: [
                    "Transport aller-retour de l'hôtel à la ville de Colón",
                    "guides certifiés",
                    "Billets pour les lieux mentionnés ci-dessus",
                    "Déjeuner",
                    "Retour en train historique"
                ]
            },

            not_included:{
                es: [
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí"
                ],
                en: [
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici"
                ]
            },

            cloths: {
                es: "Cómoda y fresca",
                en: "Comfortable and fresh",
                fr: "Confortables et frais"
            }

        },
        {
            id: 9,
            time: {
                es: "+8 horas",
                en: "+8 hours",
                fr: "+8 heures"
            },

            title: {
                es: "TABOGA DAYPASS",
                en: "TABOGA DAYPASS",
                fr: "CARTE JOURNALIERE TABOGA"
            },

            name: "isla_taboga",

            location: {
                es: "Panamá",
                en: "Panamá",
                fr: "Panamá"
            },
            description: {
                es: ["Isla de Taboga, o la Isla de las Flores, como también es conocida, es una isla volcánica caracterizada por una rica vida vegetal tropical, así como hermosas playas y arena suave. Ubicada en el lado Pacífico del país en el Golfo de Panamá, esta majestuosa isla se encuentra a 20 km de la capital, lo que la convierte en la mejor escapada de un día desde la ciudad de Panamá"],
                en: ["Taboga Island, or the Island of Flowers, as it is also known, is a volcanic island characterized by rich tropical plant life, as well as beautiful beaches and soft sand. Located on the Pacific side of the country in the Gulf of Panama, this majestic island is 20 km from the capital, making it the best day trip from Panama City."],
                fr: ["Île de Taboga, ou l'île aux fleurs, comme on l'appelle aussi, est une île volcanique caractérisée par une riche flore tropicale, ainsi que de belles plages et du sable doux. Située du côté Pacifique du pays dans le golfe de Panama, cette île majestueuse se trouve à 20 km de la capitale, ce qui en fait la meilleure excursion d'une journée au départ de Panama City."]
            },
            activity: {
                es: [
                    "Paseo en barco muy agradable hasta la isla",
                    "Día de balneario en las hermosas playas de Taboga",
                    "Recorrido por la isla con un guía conocedor"
                ],
                en: [
                    "Very nice boat ride to the island",
                    "Spa day on the beautiful beaches of Taboga",
                    "Island tour with a knowledgeable guide"
                ],
                fr: [
                    "Très belle promenade en bateau vers l'île",
                    "Journée spa sur les belles plages de Taboga",
                    "Tour de l'île avec un guide expérimenté"
                ]
            },

            includes: {
                es: [
                    "Transporte terrestre ida y vuelta desde el hotel al puerto de embarque",
                    "Transporte acuático ida y vuelta desde el puerto a la isla",
                    "Tarifa del viaje",
                    "Sombrilla y silla playera",
                    "Recibida y recorrida por un guía de la isla",
                    "Almuerzo"
                ],
                en: [
                    "Round trip ground transportation from the hotel to the embarkation port",
                    "Round trip water transport from the port to the island",
                    "trip fee",
                    "Umbrella and beach chair",
                    "Greeted and toured by an island guide",
                    "Lunch"
                ],
                fr: [
                    "Transport terrestre aller-retour de l'hôtel au port d'embarquement",
                    "Transport aller-retour par eau du port à l'île",
                    "frais de déplacement",
                    "Parasol et chaise de plage",
                    "Accueil et visite par un guide de l'île",
                    "Déjeuner"
                ]
            },

            not_included:{
                es: [
                    "Bebidas alcohólicas",
                    "Otras actividades no especificadas aquí",
                    "Otras comidas no especificadas aquí"
                ],
                en: [
                    "Alcoholic drinks",
                    "Other activities not specified here",
                    "Other meals not specified here"
                ],
                fr: [
                    "Boissons alcoolisées",
                    "Autres activités non spécifiées ici",
                    "Autres repas non spécifiés ici"
                ]
            },

            cloths: {
                es: "Vestido de baño, cómoda y fresca",
                en: "Bathing suit, comfortable and fresh",
                fr: "Maillot de bain, confortable et frais"
            }

        },
        {
            id: 10,
            time: {
                es: "+8 horas",
                en: "+8 hours",
                fr: "+8 heures"
            },

            title: {
                es: "Centro Comercial Albrook",
                en: "Albrook Mall",
                fr: "Centre commercial Albrook"
            },

            name: "albrook_mall",

            location: {
                es: "Panamá",
                en: "Panamá",
                fr: "Panamá"
            },
            description: {
                es: ["El Centro Comercial más grande de Latinoamérica, con ciento y ciento de almacenes para toda la familia, restaurantes, amenidades, cafeterías, zapaterías y mucho más.  Encontrará el regalo soñado!"],
                en: ["The largest shopping center in Latin America, with hundreds and hundreds of stores for the whole family, restaurants, amenities, cafeterias, shoe stores and much more. You will find the dream gift!"],
                fr: ["Le plus grand centre commercial d'Amérique latine, avec des centaines et des centaines de magasins pour toute la famille, des restaurants, des commodités, des cafétérias, des magasins de chaussures et bien plus encore. Vous trouverez le cadeau de rêve!"]
            },
            activity: {
                es: [
                    "Cines",
                    "Restaurantes",
                    "Casino",
                    "Tiendas de Ropa y Calzado",
                    "Tiendas de Souvenir"
                ],
                en: [
                    "Movie Theater",
                    "Restaurants",
                    "Casino",
                    "Clothing and Footwear Stores",
                    "Souvenir Shops"
                ],
                fr: [
                    "Cinémas",
                    "Restaurants",
                    "Casino",
                    "Magasins de vêtements et de chaussures",
                    "Boutiques de souvenirs"
                ]
            },

            includes: {
                es: [

                ],
                en: [

                ],
                fr: [

                ]
            },

            not_included:{
                es: [

                ],
                en: [

                ],
                fr: [

                ]
            },

            cloths: {
                es: "Cómoda",
                en: "Comfortable",
                fr: "Confortable"
            }

        },
    ]


}