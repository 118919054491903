import {useSelector} from "react-redux";
import {Container, Row, Col} from "reactstrap";
import SectionTitle from "../../../components/common/section-title";
import React from "react";
import {PanamaInfoContent} from "./content/panamaInfo";
import ServiceBox from "../../../components/Services/service-box";

export const PanamaInfo = () => {

    const ln = useSelector((state) => state.language.selected_language);


    return (
        <React.Fragment>
            <section className="section bg-panama" id="blog">
                <div className="bg-overlay-2"></div>
                <Container style={{color:"white"}}>

                    <Row className="mt-5">
                        <h1 className={"amatic-font-stlye text-center"}>
                            {PanamaInfoContent.title[ln]}
                        </h1>
                        {
                            PanamaInfoContent.info.map((info, key) => (
                            <ServiceBox
                                key={key}
                                title={info.title[ln]}
                                icon={info.icon}
                                description={<p style={{color:"white"}}>{info.text[ln]}</p>}
                            />
                        ))}
                    </Row>

                </Container>
            </section>
        </React.Fragment>

    )
}