import {NavigationBar} from "../components/Navbar";
import Clients from "../components/Clients/clients";
import Features from "../components/Features/features";
import Services from "../components/Services/services";
import WebsiteDescription from "../components/Website Description/website-description";
import AboutUs from "../components/About Us/about-us";
import Pricing from "../components/Pricing/pricing";
import Testimonials from "../components/Testimonials/testimonials";
import GetStarted from "../components/Get Started/get-started";
import Blog from "../components/Blog/blog";
import ContactUs from "../components/Contact Us/contact-us";
import Footer from "../components/Footer";
import React from "react";


// export const GeneralLayout = () => {
//
//     return(
//         <React.Fragment>
//             {/*<NavbarPage navClass={"navbar-white"}/>*/}
//             <Blog/>
//             <ContactUs/>
//             <Footer/>
//         </React.Fragment>
//     )
// }

class GeneralLayout extends React.Component {


    render() {

        const Component = this.props.component;

        return (
            <React.Fragment>
                <NavigationBar navClass={"navbar-white"}/>
                <Component />
                {/*<Blog/>*/}
                {/*<ContactUs/>*/}
                <Footer/>
            </React.Fragment>
        )
    }
}

export {GeneralLayout};
