import React, {Component} from 'react'
import {Col, Container, Row} from 'reactstrap'
import Content from "./components/Content";
import SocialMedia from "./components/SocialMedia";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={"custom-footer"}>
                    <Container>
                        <Content />
                    </Container>
                </div>

                {/*<div style={{width: '100%', backgroundColor: '#09426d'}}>*/}
                {/*    <Container style={{padding:'25px 0px 10px 0px'}}>*/}
                {/*        <SocialMedia />*/}
                {/*    </Container>*/}
                {/*</div>*/}

            </React.Fragment>
        )
    }
}

export default Footer;
