import React, {useState, useEffect} from "react";
import {Container, Row, Col, Card, CardTitle, CardBody, CardText} from "reactstrap";
import {Carousel} from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import {TripsToDo} from "../../../content/tripsToDo";
import {banner} from "../../LandingPage/components/content/banner";
import {useSelector} from "react-redux";
import {GiClothes, GiSandsOfTime, GiDirectionSigns} from "react-icons/gi";
import {BsFillBookmarkStarFill, BsFillBookmarkCheckFill, BsFillBookmarkXFill} from "react-icons/bs";
import {TOURS} from "../../../utils/Images";

export const TourMoreInfo = () =>{

    const PageGlosary = {
        duration: {
            es: "Duración",
            en: "Duration",
            fr: "Durée"
        },
        outfit: {
            es: "Vestimenta",
            en: "Outfit",
            fr: "Tenue"
        },
        location: {
            es: "Ubicación",
            en: "Location",
            fr: "Emplacement"
        },
        activities:{
            es: "Actividades",
            en: "Activities",
            fr: "Activités"
        },
        includes:{
            es: "Incluye",
            en: "Includes",
            fr: "Il comprend"
        },
        not_included:{
            es: "No Incluye",
            en: "Does not include",
            fr: "N'inclut pas"
        }
    }

    const {id} = useParams()
    const [tour, setTour] = useState(TripsToDo.tours.find(tour => tour.id === parseInt(id)))
    const ln = useSelector((state) => state.language.selected_language);

    const renderDescription = () =>{
        return tour.description[ln].map(description =>{
            return(
                <Col xs={12} xl={12}>
                    <p className="custom-description-black">{description}</p>
                </Col>
            )
        })
    }

    const renderActivities = () =>{
        return tour.activity[ln].map(activity =>{
            return <li>{activity}</li>
        })
    }

    const renderIncludes = () =>{
        return tour.includes[ln].map(item =>{
            return <li>{item}</li>
        })
    }

    const renderNotIncluded = () =>{
        return tour.not_included[ln].map(item =>{
            return <li>{item}</li>
        })
    }

    return(
        <React.Fragment>
            <Container>

                <h1 className="amatic-font-stlye-2">{tour.title[ln]}</h1>

                <Carousel showArrows={true} showThumbs={false}>
                    {
                        TOURS[tour.name]['landscape'].map(img => {
                            return (
                                <div style={{maxHeight:"500px"}}>
                                    <img style={{maxHeight:"500px", objectFit:"cover"}} src={img} />
                                </div>
                            )
                        })
                    }
                </Carousel>

                <Row className={"custom-description-black"} style={{marginTop:"30px"}}>

                    <Col xs={12} xl={4} style={{marginBottom:"30px"}} c>
                        <p style={{textAlign:"center"}}><GiSandsOfTime size={40}/>{PageGlosary.duration[ln]}</p>
                        <hr style={{  border:"1px", width:"70%", margin:"auto"}}/>
                        <p style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginTop: "5px"}}>{tour.time[ln]}</p>
                    </Col>



                    <Col xs={12} xl={4} style={{marginBottom:"30px"}}>
                        <p style={{textAlign:"center"}}><GiClothes size={40}/>{PageGlosary.outfit[ln]}</p>
                        <hr style={{  border:"1px", width:"70%", margin:"auto"}}/>
                        <p style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginTop: "5px"}}>{tour.cloths[ln]}</p>
                    </Col>



                    <Col xs={12} xl={4} style={{marginBottom:"30px"}}>
                        <p style={{textAlign:"center"}}><GiDirectionSigns size={40}/>{PageGlosary.location[ln]}</p>
                        <hr style={{  border:"1px", width:"70%", margin:"auto"}}/>
                        <p style={{textAlign:"center", fontSize:"20px", fontWeight:"bold", marginTop: "5px"}}>{tour.location[ln]}</p>
                    </Col>

                </Row>

                 <Row style={{marginTop:"20px"}}>
                     {renderDescription()}
                 </Row>

                <Row style={{marginTop:"20px"}}>
                    <Col xs={12} xl={4}>
                        <Card style={{borderRadius:"20px", height:"400px"}}>
                            <CardBody>
                                <CardTitle tag="h5">
                                    <h3 style={{textAlign:"center"}} className="amatic-font-stlye"><BsFillBookmarkStarFill color={"#eebb68"}/> {PageGlosary.activities[ln]}</h3>
                                </CardTitle>
                                <CardText>
                                    <ul>{renderActivities()}</ul>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={12} xl={4}>
                        <Card style={{borderRadius:"20px", height:"400px"}}>
                            <CardBody>
                                <CardTitle tag="h5">
                                    <h3 style={{textAlign:"center"}} className="amatic-font-stlye"><BsFillBookmarkCheckFill color={"#32aaaa"}/> {PageGlosary.includes[ln]}</h3>
                                </CardTitle>
                                <CardText>
                                    <ul>{renderIncludes()}</ul>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={12} xl={4}>
                        <Card style={{borderRadius:"20px", height:"400px"}}>
                            <CardBody>
                                <CardTitle tag="h5">
                                    <h3 style={{textAlign:"center"}} className="amatic-font-stlye"><BsFillBookmarkXFill color={"#d87253"}/> {PageGlosary.not_included[ln]}</h3>
                                </CardTitle>
                                <CardText>
                                    <ul>{renderNotIncluded()}</ul>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>

    )

}