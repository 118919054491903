import React, { Component } from 'react';
import {Container} from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {Tours} from "./components/Tours";
import {TourMoreInfo} from "./components/TourMoreInfo";

const GuidedTours = () => {

    const images = `${process.env.PUBLIC_URL}/assets/images`

    return (
        <section className="section bg-light" id="blog">
            <Tours/>
        </section>
    );
}

const TourMoreInfoPage = () => {
    return (
        <section className="section bg-light" id="blog">
            <TourMoreInfo/>
        </section>
    )
}

export {GuidedTours, TourMoreInfoPage}

