export const TripsNew = [
    {
        id: "cbe9e527-cce8-45ad-912c-ab98afbb306e",
        name: "azuero",
        location: {
            label: {
                es: "Provincias de Herrera y Los Santos",
                en: "Provinces of Herrera and Los Santos",
                fr: "Provinces de Herrera et de Los Santos"
            },
            value: "herrera"
        },
        title: {
            es: "Azuero",
            en: "Azuero",
            fr: "Azuero"
        },
        description: {
            es: [
                "Azuero, es la península más grande de Panamá, compuesta por 2 provincias, Herrera y Los Santos, quienes con mucho orgullo y recelo cuidan de las tradiciones y culturas heredadas, se caracterizan por sus holgorios y alegrías en tiempo de carnavales y otras fechas conmemorables.",
                "Se podrá apreciar casas, cabañas, iglesias, parques, y otras estructuras que aún guardan el estilo colonial, al igual que encontrarse al hombre campesino en sus faenas diarias.",
                "Es de mucho interés visitar a los artesanos que con mucho talento y dedicación confeccionan Las Polleras (vestuario femenino de esplendor), Los maestros mascareros, los talleres de comidas típicas del área, Los orfebres que confeccionas joyas que acompañan los trajes folclóricos, los esculpidores del barro y la arcilla, o sencillamente al panadero del pueblo que confecciona el pan y las delicias que lo deleitaran.",
                "Que conocer en estas regiones, Los Artesanos, Parque Nacional Sarigua, El Museo de la Nacionalidad en la Villa de Los Santos, Hacienda San Isidro, dedicados a la producción del ron panameño, una verdadera experiencia.  Muy recomendado por tratarse de un tour de cultura y conocimientos y un verdadero deleite para el paladar."
            ],
            en: [
                "Azuero, is the largest peninsula in Panama, made up of 2 provinces, Herrera and Los Santos, who with great pride and suspicion take care of inherited traditions and cultures, are characterized by their revels and joys in carnival time and other commemorative dates.",
                "You will be able to appreciate houses, cabins, churches, parks, and other structures that still keep the colonial style, as well as meeting the peasant man in his daily tasks.",
                "It is of great interest to visit the artisans who, with great talent and dedication, make Las Polleras (splendorous female clothing), the mascareros masters, the typical food workshops of the area, the goldsmiths who make jewelry that goes with the folk costumes, the sculptors of the mud and clay, or simply the village baker who makes the bread and delicacies that will delight you.",
                "What to know in these regions, Los Artesanos, Sarigua National Park, The Museum of Nationality in Villa de Los Santos, Hacienda San Isidro, dedicated to the production of Panamanian rum, a true experience. Highly recommended for being a tour of culture and knowledge and a true delight for the palate.",
            ],
            fr: [
                "Azuero, est la plus grande péninsule du Panama, composée de 2 provinces, Herrera et Los Santos, qui avec beaucoup de fierté et de méfiance prennent soin des traditions et des cultures héritées, se caractérisent par leurs réjouissances et leurs joies en période de carnaval et autres dates commémoratives.",
                "Vous pourrez apprécier des maisons, des cabanes, des églises, des parcs et d'autres structures qui conservent encore le style colonial, ainsi que rencontrer le paysan dans ses tâches quotidiennes.",
                "Il est très intéressant de visiter les artisans qui, avec beaucoup de talent et de dévouement, fabriquent Las Polleras (costumes féminins de splendeur), les maîtres mascareros, les ateliers alimentaires typiques de la région, les orfèvres qui fabriquent des bijoux qui accompagnent les costumes folkloriques, les sculpteurs de boue et d'argile, ou tout simplement le boulanger du village qui fabrique le pain et les gourmandises qui vous régaleront.",
                "Ce qu'il faut savoir dans ces régions, Los Artesanos, le parc national de Sarigua, le musée de la nationalité à Villa de Los Santos, l'Hacienda San Isidro, dédiée à la production de rhum panaméen, une véritable expérience. Fortement recommandé pour être un tour de culture et de connaissances et un vrai délice pour le palais."
            ]
        },

    },
    {
        id: "e2bb6bdf-2a97-4f3f-82cb-3eee5abf8922",
        name: "boca_chica",
        location: {
            label: {
                es: "Provincia de Chiriquí",
                en: "Chiriquí Province",
                fr: "Province de Chiriquí"
            },
            value: "chiriqui"
        },
        title: {
            es: "Boca Chica",
            en: "Boca Chica",
            fr: "Boca Chica"
        },
        description: {
            es: [
                "A tan solo 50 minutos en avión desde ciudad de Panamá hasta la ciudad de David, y 45 minutos más vía terrestre, o aproximadamente a 5 horas por la carretera Interamericana desde la ciudad de Panamá. Es un destino perfecto de balneario y relajamiento. Archipiélago compuestopor pequeños islotes con playas de arena blanca y aguas cristalinas.",
                "En este paradisiaco Spot tendrá la oportunidad de practicar la pesca deportiva, hacer snorkel,  el avistamiento de ballenas jorobadas que visitan nuestras cálidas aguas desde el mes de julio a septiembre, o simplemente relajarse y disfrutar de las hermosas playas y la naturaleza que las rodea."
            ],
            en: [
                "Just 50 minutes by plane from Panama City to the city of David, and 45 minutes more by land, or approximately 5 hours by the Inter-American Highway from Panama City. It is a perfect spa and relaxation destination. Archipelago made up of small islets with white sand beaches and crystal clear waters.",
                "In this paradisiacal Spot you will have the opportunity to practice sport fishing, snorkel, watch humpback whales that visit our warm waters from July to September, or simply relax and enjoy the beautiful beaches and the nature that surrounds them."
            ],
            fr: [
                "À seulement 50 minutes en avion de Panama City à la ville de David, et 45 minutes de plus par voie terrestre, soit environ 5 heures par l'autoroute interaméricaine de Panama City. C'est une destination idéale pour le spa et la détente. Archipel composé de petits îlots aux plages de sable blanc et aux eaux cristallines.",
                "Dans ce spot paradisiaque, vous aurez la possibilité de pratiquer la pêche sportive, la plongée en apnée, d'observer les baleines à bosse qui visitent nos eaux chaudes de juillet à septembre, ou simplement de vous détendre et de profiter des belles plages et de la nature qui les entoure."
            ]
        },
    },
    {
        id: "845e2d20-3427-40ae-82cc-b59e66261a5e",
        name: "bocas_del_toro",
        location: {
            label: {
                es: "Provincia de Bocas del Toro",
                en: "Bocas del Toro Province",
                fr: "Province de Bocas del Toro"
            },
            value: "panama"
        },
        title: {
            es: "Bocas Del Toro",
            en: "Bocas Del Toro",
            fr: "Bocas Del Toro"
        },
        description: {
            es: [
                "Auténtico pueblo costeño, a tan solo 60 minutos en avión desde la ciudad de Panamá. Es uno de los destinos preferido por los visitantes por sus parajes encantadores de aguas cristalinas y cálidas. Es un imperdible para los amantes del ecoturismo.",
                "Bocas del Toro, conocida como el ‘Galápagos del Caribe”, es uno de los lugares biológicamente más diversos del planeta. Aquí encontraras hoteles de todas las categorías, y también pequeños hostales, que harán de tu estadía una experiencia inolvidable.",
                "Para los que les gustan las fiestas, Isla Colón es el lugar, pues sobre su calle principal hay sin fines de restaurantes nacionales e internaciones, al igual que terrazas y bares, ideal para terminar un día de playa y sol.",
                "Entre las actividades que podrá realizar, están: Navegar por las distintas islas, hacer snorkel o bucear entre los arrecifes y corales llenos de vida marina y más diversos de la región, entre otras muchas aventuras.  La verdad es que aquí no te alcanzarán los días por la cantidad de actividades que se te ofrecen para hacer."
            ],
            en: [
                "Bocas Del Toro: Authentic Coastal village, only just 60 minutes by plane from Panama City. It is one of the destinations preferred by visitors for its charming places with crystal clear and warm waters. It is a must for lovers of ecotourism.",
                "Bocas del Toro, known as the 'Galapagos del Caribe', is one of the most biologically diverse places on the planet. Here you will find hotels of all categories, as well as small hostels, that will make your stay an unforgettable experience.",
                "For those who like parties, Isla Colón is the place, because on its main street there are endless national and international restaurants, as well as terraces and bars, ideal to end a day at the beach and sun.",
                "Among the activities you can do are: Sailing the different islands, snorkeling or diving among the reefs and corals full of marine life and more diverse of the region, among many other adventures. The truth is that here you will not be reached by the days because of the number of activities offered to do."
            ],
            fr: [
                "Bocas Del Toro: Authentique village côtier, à seulement 60 minutes en avion de Panama City. C’est l’une des destinations préférées par les visiteurs pour ses endroits charmants avec des eaux cristallines et tiède.",
                "C’est un must pour les amateurs d’écotourisme. Bocas del Toro, connu sous le nom de « Galapagos del Caribe », est l’un des endroits les plus biologiquement diversifiés de la planète. Ici, vous trouverez des hôtels de toutes catégories, ainsi que de petites auberges, qui feront de votre séjour une expérience inoubliable.",
                "Pour ceux qui aiment faire la fête, Isla Colón est l'endroit idéal, car dans sa rue principale se trouvent d'innombrables restaurants nationaux et internationaux, ainsi que des terrasses et des bars, idéaux pour terminer une journée à la plage et au soleil.",
                "Parmi les activités que vous pouvez faire sont: naviguer sur les différentes îles, la plongée avec tuba ou la plongée parmi les récifs et les coraux pleins de vie marine et plus diversifiée de la région, parmi beaucoup d’autres aventures. La vérité est qu’ici, vous ne serez pas atteint par les jours en raison de la quantité d’activités offertes à faire."
            ]
        },
    },
    {
        id: "315d5bc1-4c98-4936-957e-8fb52a3fb7a0",
        name: "boquete",
        location: {
            label: {
                es: "Provincia de Chiriquí",
                en: "Chiriquí Province",
                fr: "Province de Chiriquí"
            },
            value: "chiriqui"
        },
        title: {
            es: "Boquete",
            en: "Boquete",
            fr: "Boquete"
        },
        description: {
            es: [
                "Llegará a la ciudad de David en tan solo 50 minutos vía aérea desde la ciudad de Panamá, y  luego 45 minutos vía terrestre, arribará a este pueblo pintoresco de clima templado, donde le esperaran infinidades de  actividades a realizar, tales como la tirolesa, senderismo, escalada, puentes colgantes, pozos de aguas termales, avistamientos de aves, descensos en rápidos, visitas a granjas agrícolas y florales, tour del café y del ron, vivirás un contacto directo con la naturaleza, y como si fuera poco, al final de un día intenso de actividades, podrás disfrutar de infinidades de restaurantes internacionales y terrazas a lo largo del pueblo, donde de seguro pasarás momentos inolvidables compartiendo tus experiencias!"
            ],
            en: [
                "You will arrive in the city of David in just 50 minutes by air from Panama City, and then 45 minutes by land, you will arrive at this picturesque town with a temperate climate, where countless activities await you, such as zip-lining, hiking, climbing, suspension bridges, hot springs, bird watching, rapids descents, visits to agricultural and flower farms, coffee and rum tours, you will experience direct contact with nature, and as if that were not enough, at the end of an intense day of activities, You can enjoy countless international restaurants and terraces throughout the town, where you will surely spend unforgettable moments sharing your experiences!"
            ],
            fr: [
                "Vous arriverez dans la ville de David en seulement 50 minutes en avion depuis Panama City, puis en 45 minutes par voie terrestre, vous arriverez dans cette ville pittoresque au climat tempéré, où d'innombrables activités vous attendent, comme la tyrolienne, la randonnée , escalade, ponts suspendus, sources chaudes, observation d'oiseaux, descentes de rapides, visites de fermes agricoles et florales, tour du café et du rhum, vous vivrez un contact direct avec la nature, et comme si cela ne suffisait pas, à la fin d'une intense journée d'activités, vous pourrez profitez d'innombrables restaurants internationaux et terrasses dans toute la ville, où vous passerez sûrement des moments inoubliables en partageant vos expériences!"
            ]
        },
    },
    {
        id: "b2a496b6-bdcb-469e-a62a-3eecacfd55a7",
        name: "catamaran",
        location: {
            label: {
                es: "Archipiélago de San Blas - Panamá",
                en: "Archipelago of San Blas - Panamá",
                fr: "Archipel de San Blas - Panamá"
            },
            value: "panama"
        },
        title: {
            es: "CATAMARAN",
            en: "CATAMARAN",
            fr: "CATAMARAN"
        },
        description: {
            es: [
                "Disfrute de unas merecidas vacaciones de lujo sobre un cómodo Catamarán o Monocasco, donde no le faltará nada para que sienta que está en un hotel 5 estrellas, y además explorando islas y playas casi vírgenes poco exploradas del  Caribe Panameño, pertenecientes al archipiélago de San Blas.",
                "Los visitantes los solicitan una y una vez más, es inigualable! Para compararlo debes conocerlo primero.  Se llega en avión en muy corto tiempo, o por carretera sobre cordilleras, es importante indicar que las mismas no se encuentran en condiciones óptimas, por vía terrestre es aproximadamente 3 horas desde ciudad de Panamá, pero vale la pena!  El paraíso en la Tierra."
            ],
            en: [
                "Enjoy a well-deserved luxury vacation on a comfortable Catamaran or Monohull, where nothing will be missing to make you feel like you are in a 5-star hotel, and also exploring almost virgin islands and beaches little explored in the Panamanian Caribbean, belonging to the San Blas archipelago.",
                "Visitors request them over and over again, it's unmatched! To compare it you must know it first. It is reached by plane in a very short time, or by road over mountain ranges, it is important to indicate that they are not in optimal conditions, by land it is approximately 3 hours from Panama City, but it is worth it! Paradise on Earth."
            ],
            fr: [
                "Profitez de vacances de luxe bien méritées sur un catamaran ou un monocoque confortable, où rien ne manquera pour vous donner l'impression d'être dans un hôtel 5 étoiles, et explorez également des îles presque vierges et des plages peu explorées dans les Caraïbes panaméennes, appartenant à l'archipel des San Blas.",
                "Les visiteurs les demandent encore et encore, c'est inégalé ! Pour le comparer, il faut d'abord le connaître. On y accède par avion en très peu de temps, ou par la route au-dessus des chaînes de montagnes, il est important d'indiquer qu'elles ne sont pas dans des conditions optimales, par voie terrestre c'est à environ 3 heures de Panama City, mais ça vaut le coup ! Paradis sur Terre."
            ]
        },
    },
    {
        id: "a70c32f4-6e4d-4b14-9b63-68ef7b1e4fd1",
        name: "colon",
        location: {
            label: {
                es: "Provincia de Colón",
                en: "Colón Province",
                fr: "Province de Colón"
            },
            value: "colon"
        },
        title: {
            es: "Colón",
            en: "Colón",
            fr: "Colón"
        },
        description: {
            es: [
                "Colón, provincia caribeña con un gran legado africano, que aún se puede palpar en su gastronomía, sus coloridos vestuarios, y sobre todos en su baile alegre y muy peculiar, llamado Congo, declarado patrimonio inmaterial de la humanidad por la Unesco. Colón posee paisajes incomparables y una posición geográfica privilegiada.",
                "Desde la ciudad de Panamá, en  tan solo 1:20min por autopista ó 1 hora en el histórico Panama Rail Way, primer ferrocarril Transcontinental del mundo,   podrás llegar a esta interesante provincia.",
                {
                    type: "list",
                    header: "Hay mucho que hacer en esta región, por ejemplo:",
                    content: [
                        "Visitar  El Centro de Visitantes de Aguas Clara, inaugurada en 2016 para la ampliación del Canal.",
                        "Las Esclusas de Gatún, uno de los juegos de esclusas que componen el Canal de Panamá, una obra de ingeniería por el hombre digna a conocer.",
                        "Fuerte San Lorenzo y Fuerte Portobelo, entre sus paredes cuentan las historias de la época,  enfrentamientos y luchas entre piratas malvados y militares resistentes.  Estos fuertes son reconocidos por LA UNESCO, como patrimonios Mundiales de la Humanidad.",
                        "Islas paradisiacas y playas de arenas blancas  con aguas transparentes donde podrás disfrutar de jornadas de relajación y diversión, ideales para practicar kayak, remo, snorkel, buceo, etc."
                    ]
                }
            ],
            en: [
                "Colón, a Caribbean province with a great African legacy, which can still be felt in its gastronomy, its colorful costumes, and above all in its cheerful and very peculiar dance, called Congo, declared an intangible heritage of humanity by UNESCO. Colón has incomparable landscapes and a privileged geographical position.",
                "From Panama City, in just 1:20 min by highway or 1 hour on the historic Panama Rail Way, the first Transcontinental railway in the world, you can reach this interesting province.",
                {
                    type: "list",
                    header: "There is a lot to do in this region, for example:",
                    content: [
                        "Visit the Aguas Clara Visitors Center, inaugurated in 2016 for the expansion of the Canal.",
                        "The Gatun Locks, one of the sets of locks that make up the Panama Canal, a work of engineering by man worth knowing.",
                        "Fort San Lorenzo and Fort Portobelo, within its walls tell the stories of the time, confrontations and fights between evil pirates and resistant soldiers. These forts are recognized by UNESCO as World Heritage Sites.",
                        "Paradise islands and white sand beaches with transparent waters where you can enjoy days of relaxation and fun, ideal for kayaking, rowing, snorkeling, diving, etc."
                    ]
                }
            ],
            fr: [
                "Colón, une province des Caraïbes avec un grand héritage africain, qui se ressent encore dans sa gastronomie, ses costumes colorés, et surtout dans sa danse joyeuse et très particulière, appelée Congo, déclarée patrimoine immatériel de l'humanité par l'UNESCO. Colón a des paysages incomparables et une position géographique privilégiée.",
                "De Panama City, en seulement 1h20 min par l'autoroute ou 1 heure sur l'historique Panama Rail Way, le premier chemin de fer transcontinental au monde, vous pouvez rejoindre cette province intéressante.",
                {
                    type: "list",
                    header: "Il y a beaucoup à faire dans cette région, par exemple:",
                    content: [
                        "Visitez le centre des visiteurs d'Aguas Clara, inauguré en 2016 pour l'expansion du canal.",
                        "Les écluses de Gatun, l'un des ensembles d'écluses qui composent le canal de Panama, une œuvre d'ingénierie de l'homme à connaître.",
                        "Fort San Lorenzo et Fort Portobelo, dans ses murs racontent les histoires de l'époque, les affrontements et les combats entre pirates maléfiques et soldats résistants. Ces forts sont reconnus par l'UNESCO comme sites du patrimoine mondial.",
                        "Îles paradisiaques et plages de sable blanc aux eaux transparentes où vous pourrez profiter de journées de détente et de plaisir, idéales pour le kayak, l'aviron, la plongée en apnée, la plongée, etc."
                    ]
                }
            ],
        },
    },
    {
        id: "0788a4e3-3482-4536-8fe2-21c6731e5db9",
        name: "el_valle_de_anton",
        location: {
            label: {
                es: "Provincia de Coclé",
                en: "Cocle Province",
                fr: "Province de Coclé"
            },
            value: "cocle"
        },
        title: {
            es: "El Valle de Antón",
            en: "The Antón Valley",
            fr: "La vallée d’Antón"
        },
        description: {
            es: [
                "El Valle de Antón: Es una villa campestre acentuada en el cráter o caldera de un volcán extinto hace más de 300 mil años, ubicado en la provincia de Coclé, a tan solo 2 horas en auto desde la ciudad de Panamá.  Si lo suyo es el senderismo no puedes dejar pasar en alto subir la montaña de La India Dormida.",
                "Otras actividades que puede realizar en el Valle son: canopy, baños en pozos térmicos, visitar el mercado local de frutas, legumbre y artesanía, observación de aves exóticas, equitación, balnearios naturales, en fin, vivir la naturaleza a su máxima expresión."
            ],
            en: [
                "The Antón Valley: It is a country village accented in the crater or caldera of a volcano extinct more than 300 thousand years ago, located in the province of Coclé, just 2 hours by car from Panama City.",
                "If hiking is his thing, you can't let the mountain of Sleeping India pass aloft. Other activities you can do in the Valley are the canopy, baths in thermal wells, visit the local fruit market, legume, and crafts, exotic bird watching, horseback riding, natural spas, in short, live nature at its finest."
            ],
            fr: [
                "La vallée d’Antón: C’est un village de campagne accentué dans le cratère ou la caldeira d’un volcan éteint il y a plus de 300 000 ans, situé dans la province de Coclé, à seulement 2 heures de voiture de Panama City. Si la randonnée est son truc, vous ne pouvez pas laisser passer la montagne de l’Inde endormie en altitude.",
                "D’autres activités que vous pouvez faire dans la vallée sont: canopée, bains dans les puits thermiques, visiter le marché aux fruits, légumes et artisanat local, observation des oiseaux exotiques, équitation, spas naturels, en bref, vivre la nature à son meilleur."
            ],
        },
    },
    {
        id: "68b177ae-9ed3-4da3-b751-86722bbe5597",
        name: "pedasi",
        location: {
            label: {
                es: "Provincia de Los Santos",
                en: "Los Santos Province",
                fr: "Province de Los Santos"
            },
            value: "los_santos"
        },
        title: {
            es: "Pedasí",
            en: "Pedasí",
            fr: "Pedasí"
        },
        description: {
            es: [
                "Uno de los pueblos con mayores atracciones turísticas en la provincia de Los Santos, situada al extremo sur-oriente de la Península de Azuero en las costas del Pacífico.",
                "Es un pueblo pintoresco  y autóctono de la época colonial, reconocido por su alegría en los carnavales anuales, sus extensas playas, la pesca deportiva, el buceo y el surf.",
                {
                    type: "list",
                    header: "En Pedasí también podrás disfrutar de:",
                    content: [
                        "Isla Iguana, a tan solo algunos minutos desde el puerto de la Arenosa, es una hermosa playa de arena brillantemente blanca y aguas cristalinas, una verdadera piscina natural, muy visitada por los turistas, declarada como área protegida en 1981, hogar de 5000 aves fragatas.",
                        "Playa Venao: Es una comunidad de surfista, compuesta con hoteles boutique, resorts y hostales,  plenos de  personas que comparten su gran pasión, y que saben cómo disfrutar, pero de igual forma es un lugar familiar donde disfrutaras de bellos atardeceres."
                    ]
                }
            ],
            en: [
                "One of the towns with the greatest tourist attractions in the province of Los Santos, located at the extreme south-east of the Azuero Peninsula on the Pacific coast.",
                "It is a picturesque and native town from the colonial era, recognized for its joy in the annual carnivals, its extensive beaches, sport fishing, diving and surfing.",
                {
                    type: "list",
                    header: "In Pedasí you can also enjoy:",
                    content: [
                        "Isla Iguana, just a few minutes from the port of Arenosa, is a beautiful beach with brilliantly white sand and crystal clear waters, a true natural pool, much visited by tourists, declarada como área protegida en 1981, hogar de 5000 aves fragatas.",
                        "Playa Venao: It is a surfer community, made up of boutique hotels, resorts and hostels, full of people who share their great passion, and who know how to enjoy themselves, but in the same way it is a family place where you will enjoy beautiful sunsets."
                    ]
                }
            ],
            fr: [
                "L'une des villes avec les plus grandes attractions touristiques de la province de Los Santos, située à l'extrême sud-est de la péninsule d'Azuero sur la côte pacifique.",
                "C'est une ville pittoresque et natale de l'époque coloniale, reconnue pour sa joie dans les carnavals annuels, ses vastes plages, la pêche sportive, la plongée et le surf.",
                {
                    type: "list",
                    header: "À Pedasí, vous pouvez également profiter de:",
                    content: [
                        "Isla Iguana, à quelques minutes du port d'Arenosa, est une belle plage au sable d'une blancheur éclatante et aux eaux cristallines, une véritable piscine naturelle, très visitée par les touristes, déclaré zone protégée en 1981, abritant 5 000 frégates.",
                        "Playa Venao : C'est une communauté de surfeurs, composée d'hôtels-boutiques, de resorts et d'auberges, pleine de gens qui partagent leur grande passion et qui savent s'amuser, mais de la même manière c'est un lieu familial où vous apprécierez beaux couchers de soleil."
                    ]
                }
            ],
        },
    },
    {
        id: "1567bd9f-f977-4080-93ca-9874aac5ceec",
        name: "riviera_del_pacifico",
        location: {
            label: {
                es: "Provincia de Coclé",
                en: "Cocle Province",
                fr: "Province de Coclé"
            },
            value: "cocle"
        },
        title: {
            es: "Riviera del Pacífico",
            en: "Pacific Riviera",
            fr: "Riviera du Pacifique"
        },
        description: {
            es: [
                "A tan solo 1 hora y 20 minutos y de forma fácil, llegarás a la Exuberante Riviera del Pacífico, una propuesta turista muy interesante, compuestas de extensas playas de aguas tibias y resorts hoteleros, la mayoría con planes de todo incluido, que harán de su estadía placentera, haciéndole regresar una y otra vez.",
                "Contará con la posibilidad de practicar, kayak, surf, pesca, jetsky, remo, o simplemente de disfrutar del lugar.",
                "La comunidad de Riviera del Pacífico es completa y diversa, encontrará modernos centros comerciales, supermercados, restaurantes con diversas propuestas, bares, heladerías y mucho, pero mucho más."
            ],
            en: [
                "In just 1 hour and 20 minutes and in an easy way, you will reach the Exuberant Pacific Riviera, a very interesting tourist proposal, made up of extensive beaches with warm waters and hotel resorts, most with all-inclusive plans, which will make your pleasant stay, making you return again and again.",
                "You will have the possibility to practice kayaking, surfing, fishing, jet skiing, rowing, or simply enjoying the place.",
                "The Riviera del Pacífico community is complete and diverse, you will find modern shopping centers, supermarkets, restaurants with various proposals, bars, ice cream parlors and much, much more."
            ],
            fr: [
                "En seulement 1 heure et 20 minutes et de manière simple, vous atteindrez l'exubérante Riviera du Pacifique, une proposition touristique très intéressante, composée de vastes plages aux eaux chaudes et de complexes hôteliers, la plupart avec des plans tout compris, qui rendront votre séjour agréable, vous faisant revenir encore et encore.",
                "Vous aurez la possibilité de pratiquer le kayak, le surf, la pêche, le jet ski, l'aviron, ou tout simplement de profiter des lieux.",
                "La communauté Riviera del Pacífico est complète et diversifiée, vous trouverez des centres commerciaux modernes, des supermarchés, des restaurants avec diverses propositions, des bars, des glaciers et bien plus encore."
            ],
        },
    },
    {
        id: "8da0857f-0615-4d32-8d61-594e1141deda",
        name: "archipielago_san_blas",
        location: {
            label: {
                es: "Provincia de Panama",
                en: "Panama Province",
                fr: "Province de Panama"
            },
            value: "panama"
        },
        title: {
            es: "Archipiélago de San Blas",
            en: "San Blas Archipelago",
            fr: "Archipel de San Blas"
        },
        description: {
            es: [
                "Compuesto por 365 islas, una más bella que la otra, administradas por la comarca indígena Guna Yala.  Es un verdadero paraíso en la tierra, gozará de aguas transparentes y arenas blancas y brillantes, donde podrá bucear y hacer snorkel que le permitirán observar peces tropicales, estrellas de mar,  y otras especies marinas de forma fácil.",
                "Contará con la opción de alojarse en cabañas rusticas y autóctonas de sus pueblo, diseñadas sobre pilotes sobre o frente  al mar. No se lo pierda!",
                "Hay dos maneras para llegar a este paraíso terrenal: En avioneta, o por carretera/cordillera que toma aproximadamente 3 horas desde la capital a la comarca, luego mediane una embarcación tripulada por los Gunas, hasta la isla de su elección. Vale la pena indicar que estas rutas no están en las mejores condiciones."
            ],
            en: [
                "San Blas Archipelago: Composed of 365 islands, one more beautiful than the other, administered by the indigenous region Guna Yala. It is a true paradise on earth, you will enjoy transparent waters and bright white sands, where you can dive and snorkel that will allow you to observe tropical fish, starfish, and other marine species, without difficulty to do so.",
                "You will have the option to stay in rustic and native cabins of its people, designed on stilts on or in front of the sea. Don't miss it!",
                "There are two ways to get to this earthly paradise: By plane, or by road/mountain range that takes approximately 3 hours from the capital to the region, then by means of a boat manned by the Gunas, to the island of your choice. It is worth noting that these routes are not in the best condition"
            ],
            fr: [
                "Archipel de San Blas : composé de 365 îles, l’une plus belle que l’autre, administrée par la région indigène Guna Yala. C’est un véritable paradis sur terre, vous profiterez d’eaux transparentes et de sable blanc vif, où vous pourrez plonger et faire de la plongée avec tuba qui vous permettra d’observer des poissons tropicaux, des étoiles de mer et d’autres espèces marines, sans difficulté pour le faire.",
                "Vous aurez la possibilité de séjourner dans les cabines rustiques et indigènes de son peuple, conçues sur pilotis sur en face de la mer. Ne le manquez pas!",
                "Il y a deux façons de se rendre à ce paradis terrestre : En avion, ou par route/chaîne de montagnes qui prend environ 3 heures de la capitale à la région, puis au moyen d'un bateau piloté par les Gunas, jusqu'à l'île de votre choix. Il convient de noter que ces itinéraires ne sont pas dans les meilleures conditions."
            ],
        },
    },
    {
        id: "e80aeb65-1e94-424f-b904-25d4f67b3948",
        name: "santa_catalina",
        location: {
            label: {
                es: "Provincia de Veraguas",
                en: "Veraguas Province",
                fr: "Province de Veraguas"
            },
            value: "veraguas"
        },
        title: {
            es: "Santa Catalina",
            en: "Saint Catherine",
            fr: "Sainte Catherine"
        },
        description: {
            es: [
                "Ubicada en la provincia de Veraguas,  aproximadamente a 4 horas vía terrestre desde la ciudad de Panamá,  es una excelente opción para los surfistas en busca de nuevas aventuras.  Lugar tranquilo y bohémico, ideal para practicar deportes acuáticos, como es la pesca, el es snorkel,  buceo, o cualquier otra disciplina como es el yoga y la relajación.",
                {
                    type: "list",
                    header: "De igual forma puede programar tours a diferentes islas cercanas como lo son:",
                    content: [
                        "Islas de  Coiba,  reserva natural protegida de gran biodiversidad marina, declarada como patrimonio de la humanidad por UNESCO en 2005, ubicada frente a las Costas de Pacífico.",
                        "Isla Cebaco, preferida para deportista de la pesca.",
                        "Isla Gobernadora, Exuberante por su fauna marina."
                    ]
                }
            ],
            en: [
                "Located in the province of Veraguas, approximately 4 hours by land from Panama City, it is an excellent option for surfers looking for new adventures. Quiet and bohemian place, ideal for practicing water sports, such as fishing, snorkeling, diving, or any other discipline such as yoga and relaxation.",
                {
                    type: "list",
                    header: "In the same way you can schedule tours to different nearby islands such as:",
                    content: [
                        "Coiba Islands, protected natural reserve of great marine biodiversity, declared a World Heritage Site by UNESCO in 2005, located off the Pacific Coast.",
                        "Cebaco Island, preferred for fishing athletes.",
                        "Gobernadora Island, Exuberant for its marine fauna."
                    ]
                }
            ],
            fr: [
                "Situé dans la province de Veraguas, à environ 4 heures de route de Panama City, c'est une excellente option pour les surfeurs à la recherche de nouvelles aventures. Endroit calme et bohème, idéal pour pratiquer des sports nautiques, comme la pêche, le snorkeling, la plongée, ou toute autre discipline comme le yoga et la relaxation.",
                {
                    type: "list",
                    header: "De la même manière, vous pouvez programmer des visites vers différentes îles voisines telles que:",
                    content: [
                        "Les îles Coiba, réserve naturelle protégée d'une grande biodiversité marine, déclarées site du patrimoine mondial par l'UNESCO en 2005, situées au large de la côte pacifique.",
                        "L'île de Cebaco, préférée des sportifs de la pêche.",
                        "Île Gobernadora, Exubérante pour sa faune marine."
                    ]
                }
            ],
        },
    },
    {
        id: "a32cf873-66a0-4bbd-94cd-9e3bdb1c76c9",
        name: "volcan_tierras_altas",
        location: {
            label: {
                es: "Provincia de Chiriquí",
                en: "Chiriquí Province",
                fr: "Province de Chiriquí"
            },
            value: "chiriqui"
        },
        title: {
            es: "Volcán y Tierras Altas",
            en: "Volcán y Tierras Altas",
            fr: "Volcán y Tierras Altas"
        },
        description: {
            es: [
                "A tan solo 50 minutos en avión, y luego 70 minutos por vía terrestre. Este magnífico lugar es un valle rodeado de montañas que se ubica en las faldas del Volcán Barú (inactivo hace más de 1500 años) es la altura máxima del país con 3.475 mts sobre el nivel del mar.",
                "Tiene un clima templado que favorece a la agricultura y la ganadería, es por eso que podemos encontrar granjas de hortalizas y florales a todos lo largo del lugar, es su mayor actividad comercial, las mismas ofrecen visitas guiadas.",
                "Podrá realizar un sinfín de actividades tales como: Visitas a sitios arqueológicos, visitas a granjas, Parques naturales, pozos termales, observación de aves exóticas, equitación, senderismo, visitas a granjas ecuestres, tour del café y hermosos viveros, y mucho, pero mucho más."
            ],
            en: [
                "Just 50 minutes by plane, and then 70 minutes by land. This magnificent place is a valley surrounded by mountains that is located on the slopes of the Barú Volcano (inactive for more than 1500 years) is the maximum height of the country with 3,475 meters above sea level.",
                "It has a temperate climate that favors agriculture and livestock, that is why we can find vegetable and flower farms throughout the place, it is its main commercial activity, they offer guided tours.",
                "You will be able to carry out endless activities such as: Visits to archaeological sites, visits to farms, Natural Parks, thermal wells, exotic bird watching, horseback riding, hiking, visits to equestrian farms, coffee tour and beautiful nurseries, and much, much more. plus."
            ],
            fr: [
                "À seulement 50 minutes en avion, puis 70 minutes par voie terrestre. Ce lieu magnifique est une vallée entourée de montagnes qui se situe sur les pentes du volcan Barú (inactif depuis plus de 1500 ans) soit la hauteur maximale du pays avec 3475 mètres d'altitude.",
                "Il a un climat tempéré qui favorise l'agriculture et l'élevage, c'est pourquoi nous pouvons trouver des fermes maraîchères et florales partout, c'est sa principale activité commerciale, ils proposent des visites guidées.",
                "Vous pourrez réaliser d'innombrables activités telles que: Visites de sites archéologiques, visites de fermes, Parcs naturels, puits thermaux, observation d'oiseaux exotiques, équitation, randonnées, visites de fermes équestres, tour de café et de belles pépinières, et bien plus encore, bien plus encore."
            ],
        },
    },
]
