import React from "react";
import {Col, Container, Row} from "reactstrap";
import {TripsNew as Trips} from "../../../content/tripsNew";
import {FlipCard} from "./FlipCard";
import {useNavigate} from "react-router-dom";


export const TripList = () => {

    const navigate = useNavigate();

    const renderTrips = () => {
        return Trips.map(trip => {
            return (
                <Col xs={12} xl={4} key={trip.id} style={{height:"45vh"}}>
                    <FlipCard trip={trip} navigate={navigate}/>
                </Col>
            )
        })
    }

    return (
        <React.Fragment>
            <Row>
                {renderTrips()}
            </Row>
        </React.Fragment>

    )
}