import React, { Component } from 'react';
//import Section from "./section";
import {YourNextTrip} from "./components/YourNextTrip";
import {AboutUs} from "./components/AboutUs";
import {LandingPageBanner} from "./components/LandingPageBanner";
import Clients from '../../components/Clients/clients';
import Features from '../../components/Features/features';
import Services from '../../components/Services/services';
import WebsiteDescription from '../../components/Website Description/website-description';
import Pricing from '../../components/Pricing/pricing';
import Testimonials from '../../components/Testimonials/testimonials';
import GetStarted from '../../components/Get Started/get-started';
import Blog from '../../components/Blog/blog';
import ContactUs from '../../components/Contact Us/contact-us';
import Footer from '../../components/Footer/footer';
import {GeneralLayout} from "../../layouts";

/*class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
          navClass : ""
        };
    }
    componentDidMount()
    {
        document.getElementById("colorTheme").href="assets/colors/red.css";
    }
    

    render() {

    }
}*/

export const LandingPage = () => {

    return (
        <React.Fragment>
            <LandingPageBanner/>
            <YourNextTrip/>
            <AboutUs/>
        </React.Fragment>
    );

}
