import React from "react";

export const navbarcontent = {
        es: [
            { id:1, name: "home", label:"Inicio", path: "/" },
            { id:2, name: "panama", label:"Panamá", path: "panama" },
            { id:3, name: "tours", label:"Tours", path: "guided-tours" },
            { id:4, name: "todo", label:"Haz tu Trip", path: "trips" },
            { id:5, name: "bio_protocol", label:"Protocolo Bioseguridad", path: "https://www.atp.gob.pa/?page_id=372#4-qubely---a-full-fledged-gutenberg-builder" },
            { id:6, name: "contact", label:"Contactenos", path: "#contact" },
        ],
        en: [
            { id:1, name: "home", label:"Home", path: "/" },
            { id:2, name: "panama", label:"Panamá", path: "panama" },
            { id:3, name: "tours", label:"Tours", path: "guided-tours" },
            { id:4, name: "todo", label:"Build your Trip", path: "trips" },
            { id:5, name: "bio_protocol", label:"Biosafety Protocol", path: "https://www.atp.gob.pa/?page_id=372#4-qubely---a-full-fledged-gutenberg-builder" },
            { id:6, name: "contact", label:"Contact Us", path: "#contact" },
        ],
        fr: [
            { id:1, name: "home", label:"Accueil", path: "/" },
            { id:2, name: "panama", label:"Panamá", path: "panama" },
            { id:3, name: "tours", label:"Tours", path: "guided-tours" },
            { id:4, name: "todo", label:"Fais ton Voyage", path: "trips" },
            { id:5, name: "bio_protocol", label:"Protocole de biosécurité", path: "https://www.atp.gob.pa/?page_id=372#4-qubely---a-full-fledged-gutenberg-builder" },
            { id:6, name: "contact", label:"Nous Contacter", path: "#contact" },
        ],

}
