import React from "react";
import {getState, setState} from "../../localStorage";


export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"
export const SET_LANGUAGE = "SET_LANGUAGE"

export const changeLanguage = (value) => dispatch => {

    setState("language", value)

    dispatch({
        type: CHANGE_LANGUAGE,
        language: value
    })

}


export const setLanguage = () => dispatch => {

    const language = getState("language");

    if (language) {
        dispatch({
            type: SET_LANGUAGE,
            language: language
        })
    } else {
        dispatch({
            type: SET_LANGUAGE,
            language: "en"
        })
    }


}



