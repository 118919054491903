import React from "react";
import {useSelector} from "react-redux";
import {Button} from "reactstrap";
import {TRIPS} from "../../../utils/Images";

export const FlipCard = (props) => {

    const PageGlosary = {
        more:{
            es: "Ver Más",
            en: "See More",
            fr: "Voir plus"
        }
    }


    const ln = useSelector((state) => state.language.selected_language);
    const trimString = (string, length) => {
        return string.length > length ?
            string.substring(0, length) + '...' :
            string;
    };

    return(
        <div className="flip-card" onClick={() => props.navigate(`/trips/more-info/${props.trip.id}`)}>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <div className={"flip-card-img"}>
                        <img src={TRIPS[props.trip.name] ? TRIPS[props.trip.name]['portrait'][0] : null}/>
                    </div>

                    <div className={"flip-card-title"}>
                        <p style={{textAlign:"right", marginRight:"10px"}}>{props.trip.location.label[ln]}</p>
                        <h4 style={{textAlign:"right", marginRight:"10px", marginTop:"-15px", textTransform:"uppercase"}}>{props.trip.title[ln]}</h4>
                    </div>
                </div>
                <div className="flip-card-back">

                    {/*<div className="flip-card-description">*/}

                    {/*    <p>*/}
                    {/*        {trimString(props.trip.description[ln], 400)}*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className="flip-card-see-more">
                        <Button style={{borderColor: "#d67152", color:"#d67152", backgroundColor: "transparent"}}>{PageGlosary.more[ln]}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}