// import playa_venao from "../assets/img/destinations/playa_venao.jpg";
// import coiba from "../assets/img/destinations/coiba.jpg";
// import san_blas_archipielago from "../assets/img/destinations/san_blas_archipielago.jpg";
// import hotel_azul_paradise from "../assets/img/destinations/hotel_azul_paradise_bocas_del_toro.jpg";

import {TRIPS} from "../../../../utils/Images"

export const yourNextTrip = {
        title: {
            es: "CONOCE TU PROXIMO DESTINO",
            en: "KNOW YOUR NEXT DESTINATION",
            fr: "CONNAISSEZ VOTRE PROCHAINE DESTINATION"
        },
        trips: [
            {
                id: "68b177ae-9ed3-4da3-b751-86722bbe5597",
                title: {
                    es: "Playa Venao",
                    en: "Playa Venao",
                    fr: "Playa Venao"
                },
                path: "trips/more-info/68b177ae-9ed3-4da3-b751-86722bbe5597",
                images: [
                    TRIPS.playa_venao.portrait[0]
                ],
                content: {
                    es: "Si lo suyo es deporte extremo como el surf o sencillamente playas extensas con bellos atardeceres, este es su destino.   Ubicado en Pedasí, en la provincia de Los Santos, a tan solo 60 minutos en avión desde la ciudad de Panamá, luego transporte terrestre local.  Es una comunidad de surfista, compuesta con hoteles boutique, resorts y hostales,  plenos de  personas que comparten su gran pasión.",
                    en: "If your thing is an extreme sport like surfing or simply extensive beaches with beautiful sunsets, this is your destination. Located in Pedasí, in the province of Los Santos, just 60 minutes by plane from AboutPanama City, then local land transport. It is a surfer's community, composed of boutique hotels, resorts, and hostels, full of people who share their great passion.",
                    fr: "Si votre truc est le sport extrême comme le surf, ou tout simplement  se relaxe sur vastes et belles plages et apprécie de beaux couchers de soleil, alors ça c’est votre destination. Situé à Pedasí, dans la province de Los Santos, à seulement 60 minutes en avion de AboutPanama Ville, puis le transport terrestre local. C’est une communauté de surfeurs, composée d’hôtels de charme, de centres de villégiature et d’auberges, pleine de gens qui partagent leur grande passion."
                }
            },
            {
                id: "e80aeb65-1e94-424f-b904-25d4f67b3948",
                title: {
                    es: "El Parque Nacional Coiba",
                    en: "Coiba National Park",
                    fr: "Coiba National Park"
                },
                path: "trips/more-info/e80aeb65-1e94-424f-b904-25d4f67b3948",
                images: [
                    TRIPS.coiba.portrait[0]
                ],
                content: {
                    es: "Es una reserva natural situada en el archipiélago homónimo perteneciente a los distritos de Montijo y de Soná de la provincia de Veraguas, y con una extensión de 270,125 hectáreas protege ecosistemas marinos, insulares y costeros. Aproximadamente a 5 horas desde la ciudad de Panamá vía terrestre, el resto del camino se hará vía acuática. Fue declarado como Patrimonio de la Humanidad por la UNESCO en el año 2005. La gran riqueza natural del parque Coiba es la marina y en la bahía de Damas se localiza un arrecife de coral con más de 135 hectáreas de extensión, el segundo más grande del Pacífico tropical americano.",
                    en: "Is a nature reserve located in the archipelago of the same name belonging to the districts of Montijo and Soná of the province of Veraguas, and with an area of 270,125 hectares protects marine, island and coastal ecosystems. Approximately 5 hours from AboutPanama City by land, the rest of the road will be done by waterway. It was declared a World Heritage Site by UNESCO in 2005. The great natural richness of Coiba Park is the marina and Damas Bay has located a coral reef with more than 135 hectares of extension, the second largest in the American tropical Pacific.",
                    fr: "Est une réserve naturelle située dans l’archipel du même nom appartenant aux districts de Montijo et Soná de la province de Veraguas, et avec une superficie de 270.125 hectares protège les écosystèmes marins, insulaires et côtiers. À environ 5 heures de AboutPanama Ville par voie terrestre, le reste de la route se fera par voie navigable. Il a été déclaré site du patrimoine mondial par l’UNESCO en 2005. La grande richesse naturelle du parc Coiba est la marina et dans la baie de Damas est situé un récif corallien avec plus de 135 hectares d’extension, le deuxième plus grand dans le Pacifique tropical américain."
                },
            },
            {
                id: 1,
                title: {
                    es: "Archipiélago de San Blas",
                    en: "San Blas Archipelago",
                    fr: "Archipel de San Blas"
                },
                path: "guided-tours/more-info/7",
                images: [
                    TRIPS.archipielago_san_blas.portrait[0]
                ],
                content: {
                    es: "Compuesto por 365 islas, una más bella que la otra, administradas por la comarca indígena Guna Yala.  Es un verdadero paraíso en la tierra, gozará de aguas transparentes y arenas blancas y brillantes, donde podrá bucear y hacer snorkel que le permitirán observar peces tropicales, estrellas de mar,  y otras especies marinas, sin dificultades para hacerlo.  Contará con la opción de alojarse en cabañas rusticas y autóctonas de sus pueblo, diseñadas sobre pilotes sobre o frente  al mar. No se lo pierda!",
                    en: "San Blas Archipelago: Composed of 365 islands, one more beautiful than the other, administered by the indigenous region Guna Yala. It is a true paradise on earth, you will enjoy transparent waters and bright white sands, where you can dive and snorkel that will allow you to observe tropical fish, starfish, and other marine species, without difficulty to do so. You will have the option to stay in rustic and native cabins of its people, designed on stilts on or in front of the sea. Don't miss it!",
                    fr: "Archipel de San Blas : composé de 365 îles, l’une plus belle que l’autre, administrée par la région indigène Guna Yala. C’est un véritable paradis sur terre, vous profiterez d’eaux transparentes et de sable blanc vif, où vous pourrez plonger et faire de la plongée avec tuba qui vous permettra d’observer des poissons tropicaux, des étoiles de mer et d’autres espèces marines, sans difficulté pour le faire. Vous aurez la possibilité de séjourner dans les cabines rustiques et indigènes de son peuple, conçues sur pilotis sur en face de la mer. Ne le manquez pas!"
                },
            },
            // {
            //     id: 5,
            //     title: {
            //         es: "Bocas del Toro",
            //         en: "Bocas del Toro",
            //         fr: "Bocas del Toro"
            //     },
            //     images: [],
            //     content: {
            //         es: "Auténtico pueblo costeño, a tan solo 60 minutos en avión desde la ciudad de Panamá. Es uno de los destinos preferido por los visitantes por sus parajes encantadores de aguas cristalinas y cálidas.   Es un imperdible  para los amantes del ecoturismo. Bocas del Toro, conocida como el ‘Galápagos del Caribe”, es uno de los lugares biológicamente más diversos del planeta. Aquí encontraras hoteles de todas las categorías, y también pequeños hostales, que harán de tu estadía una experiencia inolvidable. Entre las actividades que podrá realizar, están: Navegar por las distintas islas, hacer snorkel o bucear entre los arrecifes y corales llenos de vida marina y más diversos de la región, entre otras muchas aventuras.  La verdad es que aquí no te alcanzarán los días por la cantidad de actividades que se te ofrecen para hacer.",
            //         en: "Authentic Coastal village, only just 60 minutes by plane from AboutPanama City. It is one of the destinations preferred by visitors for its charming places with crystal clear and warm waters. It is a must for lovers of ecotourism. Bocas del Toro, known as the 'Galapagos del Caribe', is one of the most biologically diverse places on the planet. Here you will find hotels of all categories, as well as small hostels, that will make your stay an unforgettable experience. Among the activities you can do are: Sailing the different islands, snorkeling or diving among the reefs and corals full of marine life and more diverse of the region, among many other adventures. The truth is that here you will not be reached by the days because of the number of activities offered to do.",
            //         fr: "Authentique village côtier, à seulement 60 minutes en avion de AboutPanama City. C’est l’une des destinations préférées par les visiteurs pour ses endroits charmants avec des eaux cristallines et tiède. C’est un must pour les amateurs d’écotourisme. Bocas del Toro, connu sous le nom de « Galapagos del Caribe », est l’un des endroits les plus biologiquement diversifiés de la planète. Ici, vous trouverez des hôtels de toutes catégories, ainsi que de petites auberges, qui feront de votre séjour une expérience inoubliable. Parmi les activités que vous pouvez faire sont: naviguer sur les différentes îles, la plongée avec tuba ou la plongée parmi les récifs et les coraux pleins de vie marine et plus diversifiée de la région, parmi beaucoup d’autres aventures. La vérité est qu’ici, vous ne serez pas atteint par les jours en raison de la quantité d’activités offertes à faire."
            //     },
            // }
        ]
    }