import {applyMiddleware, createStore, compose} from "redux";
import rootReducer from "./reducer/rootReducer";
import thunk from "redux-thunk"

const initialState = {};
const middleWare = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleWare))
);

export default store;